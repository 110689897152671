.doubleContainer {
  position: relative;
  width: 50%;
  min-height: calc(100vh );
  flex: 1 1 50%;
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  flex-direction: column;

 }

.videoDouble {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1;
}

.doubleVideoContainer {
  width: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 97px);
  opacity: 0.9;
 }

.titleDigital {
  color: var(--White, #FFF);
  font-family: Goldman;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -2.32px;
  text-transform: uppercase;
  margin: 0px auto 40px auto;
  position: relative;
  text-align: center;
}

.containerText {
  margin: 0 auto 60px auto ;
  color:  white;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.16px;
  border: 2px solid rgba(255, 255, 255, 0.17);
  padding: 24px;
  background: rgba(91, 91, 91, 0.17);
  border-radius: 20px;
  display: inline-block;
  text-align: center;

 }

.firstParagraph,.secondParagraph {
  max-width: 500px;
  width: 100%;
}

.secondParagraph {
  margin-top: 10px;
  }

.secondParagraph span {
  display: inline-block;
  margin-top: 20px;
}

@media (min-width: 1920px) {
  .containerText {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .containerText {
    display: block;
    margin: 0 10px 0 10px;
   }
  .secondParagraph {
    margin: 20px 0 0 0;
  }
  .secondParagraph span {
    display: inline;
    margin-top: 0;
  }
  .firstParagraph,.secondParagraph {
    max-width: 553px;
    width: 100%;
  }
  .titleDigital {
    font-size: 48px;
    margin: 00px 10px 40px  10px;
   }
}

@media (max-width: 1024px) {
  .doubleContainer {
    display: block;
    width: 100%;
    min-height: auto;
    padding-bottom: 40px;
  }

  .doubleVideoContainer {
    width: 100%;
  }
  .titleDigital {
    font-size: 48px;
    padding-top: 40px;
   }

  .containerText {
     display: block;
     width: 60%;
    margin: auto;
  }

}

@media (max-width: 834px) {
  .titleDigital {
    font-size: 43px;
  }
  .containerText {
    display: block;
    width: 70%;
    margin: 0 auto 0 auto;
  }

}

@media (max-width: 500px) {
  .titleDigital {
    font-size: 25px;
  }
  .containerText {
    display: block;
    width: 90%;
    margin: -15px auto;
    font-size: 16px;
  }
}