.headerDash {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.containerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 26px;
  position: relative;
}
  .mainHeader {
  position: relative;
}

.menuIcon {
  margin-left: 20px;
  color: white;
  display: none;
}

.wrapperNameImage {
   display: flex;
 }

.userName {
  color: white;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.imageLogo {
  width: 40px;
  height: 40px;
  background: #1b4f72;
  border-radius: 50%;
  cursor: pointer;
}

.mobileMenuContent {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #000000;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  z-index: 10;
}

.closeButton {
  width: 66px;
  height: 66px;
  flex-shrink: 0;
  background: rgba(247, 247, 247, 0.10);
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 15px;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #FFFFFF;
  display: none;
}

.imageMob {
  display: none;
}

.containerMb {

}
@media (max-width: 1030px) {
  .menuIcon {
    display: block;
    &.menuIconActive {
      display: none;
    }
    }

  .containerHeader {
    display: flex;
    align-items: center;
  }


  .containerMb {
    display: block;
    min-height: calc(100vh);
    background-color: #000000;
   }
  .main-nav,
  .user-actions{
    display: none;
  }
  .activeCloseButton{
    display: block;
  }

}

@media (max-width: 420px) {
  .logoWrapper {
    width: 90px;
  }
  .imageLogo {
    width: 30px;
    height: 30px;
  }
  .userName {
    color: white;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
}