.btnWrapperHeader {
  margin: auto;
  text-align: center;
}


.buttonContainer {
  background-color: #ff4500; /* Оранжевый фон */
  width: 100%;
}
.buttonContainer,.unavailable {
  position: relative;
  display: inline-block;
  transition: .3s;
  border-radius: 13px;
}

.unavailable {
  background: var(--white-50, rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(15px);
  width: 100%;
  & .button {
    color: var(--Gray, #B0B7B9);
  }
}

.button {
  color: white;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.buttonContainer:hover {
  background-color: #FF2E00;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  z-index: 2; /* Поверх кнопки */
}


@media  (max-width: 500px) {
  .buttonContainer {
   }
  .button {
    font-size: 14px;
  }
  .dot {
    width: 4px;
    height: 4px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    z-index: 2; /* Поверх кнопки */

  }

}