.btnWrapperHeader {
  max-width: 420px;
  margin: auto;
  text-align: center;
}

.buttonContainer {
  width: 100%;
  margin: 30px auto 0 auto;
}
.buttonContainer {
  position: relative;
  display: inline-block;
  background-color: #ff4500; /* Оранжевый фон */
  transition: .3s;
  border-radius: 13px;
}

.button {
  color: white;
  border: none;
  padding: 20px 0;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.buttonContainer:hover {
  background-color: #FF2E00;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  z-index: 2; /* Поверх кнопки */
}
