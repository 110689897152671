.contentIconStatus {
  max-width: 230px;
  padding: 10px 16px;
  margin-top: 10px;
  border: 1px solid #EDEDED;
}

.contentIconStatus ,.contentIconAdd {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFA;
  border-radius: 22px;
  padding: 7px 16px;
}


.container {
  position: relative;
  width: 100%;
  max-width: 230px;
}

.selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
  color: #5A5A5A;
  font-size: 12px;
}

.dropdown {
  width: 230px;
  margin-top: 20px;
  position: absolute;
  top: 100%;
  left: -10px;
  right: 0px;
  border-radius: 22px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 10;
  background-color: #fff;
}


.list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 150px;
  overflow-y: auto;
}

.list input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #828282;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  position: relative;
  top: 4px;
}
.list input[type="checkbox"]:checked {
  background-color: #DF450F;
  border-color:  #DF450F;
}
.list input[type="checkbox"]::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 5px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s ease;
}
.list input[type="checkbox"]:checked::after {
  opacity: 1;
}

.listItem {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #f0f0f0;
  }
}

.nameCheckbox {
  color: #5A5A5A;
  font-size: 12px;
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  margin-left: 10px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.arrowIconAside ,.arrowIcon,.arrowIconExecutor {
  font-size: 15px !important;
  cursor: pointer;
}

.statusAside  {
  color: #5A5A5A;
  font-weight: 500;
  margin-top: 20px;
}

@media (max-width: 834px) {
  .orderContentMb{
    order:-1;
    margin-left: 20px;
  }
}

@media (max-width: 550px) {
  .contentIconStatus {
    max-width: 100%;
    margin-right: 20px;
  }
  .statusAside {
    margin-top: 10px;
  }
}