.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  backdrop-filter: blur(10px); /* Размытие фона */
  -webkit-backdrop-filter: blur(10px)
}

.modalContainer {
  background: white;
  border-radius: 20px;
  width: 100%;
  max-width: 801px;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  margin: 0 20px;
}

.backLink {
  width: 44px;
  padding: 10px;
  margin: 40px 0 0 40px;
  border-radius: 18px;
  color: black;
  background: rgba(97, 95, 95, 0.1);
  position: absolute;
  right: 1%;
  top: -3%;
  cursor: pointer;
}

.titleModal {
  color:  #000000;
  font-family: Goldman;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -2.32px;
  text-transform: uppercase;
  margin: 20px 0 20px 20px;
}

.border {
  border-top: 1px solid #E1E1E1;
}

.wrapperContentComment {
  display: flex;
 }

.contentComment {
  margin: 0 20px;
  border-right: 1px solid #E1E1E1;
}

.userDescription {
  color: #5A5A5A;
  font-size: 12px;
  font-weight: 600;
  padding-top: 20px;
}

.description {
  color: #5A5A5A;
  font-size: 16px;
  line-height: 19.2px;
  margin: 10px 20px 10px 0;
  max-width: 471px;
  width: 100%;
}

.contentIconAdd {
  width: 155px;
}

.contentIconAdd {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFA;
  border-radius: 22px;
  padding: 7px 16px;
}

.addTitle,.addTitleStatus,.addTitleExecutor {
  color: #5A5A5A;
  font-size: 12px;
}

.containerBtn {
  display: flex;
  margin-top: 80px;
}

.containerBtn button {
  font-weight: 600;
  padding: 8px;
  border-radius: 8px;
}

.notActive {
  color: #B0B7B9;
  background: #edecec;
}

.activeButton {
  background: black;
  color: white;
}
.btnChronology {
  margin-left: 8px;
}

.containerInput {
  margin-top: 16px;
  max-width: 481px;
  width: 100%;
  position: relative;
  padding-bottom: 20px;
}

.inputComment {
  width: 100%;
  border: 1px solid #E1E1E1;
  border-radius: 16px;
  padding: 12px 20px;
}

.arrowIcon {
  position: absolute;
  top: 16px;
  right: 6px;
}

.arrowIconAside ,.arrowIcon,.arrowIconExecutor {
  font-size: 15px !important;
  cursor: pointer;
}

.aside {
  flex: 1;
  position: relative;
}

.wrapperBtn {
  margin: 46px 20px 0 0;
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;
}

.chronologyMain {
  margin: 20px 0 20px 0;
  overflow-x: auto;
  max-height: 100px;
 }

.containerDataChronology {
  display: flex;
  max-width: 250px;
  width: 100%;
  align-items: center;
}

.userNameChronology,.timeChronology {
  margin-left: 8px;
}
.handleSubmitComment {
  background: #1b4f72;
  height: 0;
}
.avatarChronology {
  width: 26px;
  height: 26px;
  background: steelblue;
  border-radius: 16px;
}

.cardBehavior {
  margin: 8px 14px 0 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.16px;
}

.marginBtnAside {
  margin: 66px 20px 0 0;
}

.comment {
  margin-top: 24px;
}

.userComment {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.16px;
  padding: 20px;
  background: #F7F7F7;
  border-radius: 16px;
  width: 481px;
  overflow: hidden;
  min-height: 50px;
  margin-top: 10px;
 }

.commentBlock {
  margin-top: 10px;
}

.commentWrapper {
  overflow-y: scroll;
  margin-top: 20px;
  scrollbar-width: none;
  //height: 220px;
 }
.workerComment {
  margin-right: 8px;
  font-weight: 600;
}
.commentCreated_at {
  margin-left: 8px;
  color: #5A5A5A;
  font-weight: 600;
}

.mainWorkerComment {
  display: flex;
  align-items: center;
  margin-left: 3px;
  margin-top: 10px;
}

.deleteComment {
  color: #5A5A5A;
  font-size: 12px;
  margin: 8px;
  transition: .2s;
 }

.deleteComment:hover {
  transition: .2s;
  color: #DF450F;
}

@media (max-width: 834px) {
  .modalContainer {
    max-width: 601px;
    margin: 0 20px;
  }
  .contentComment {
    border-right:none;
  }
    .titleModal {
      margin-right: 60px;
    }
  .wrapperContentComment {
    display: grid;
   }
  .orderContentMb{
    order:-1;
    margin-left: 20px;
  }
  .aside {
    display: contents;
  }
  .wrapperBtn {
    margin:-30px 10px 10px 10px;
    position: static;
  }
  .containerBtn {
    display: flex;
    margin-top: 30px;
  }
  .backLink {
    right: 2%;
    top: -3%;
   }
  .executor {
    margin-top: 20px;
  }
}

@media (max-width: 550px) {
  .contentIconStatus {
    max-width: 100%;
    margin-right: 20px;
  }
  .modalContainer {
    margin: 0 15px;
  }
  .containerBtn {
    margin-top: 10px;
  }
  .titleModal {
    font-size: 24px;
    margin: 10px 50px 10px 10px;
  }
  .wrapperBtn {
    margin: -30px  10px 10px 10px;
  }
  .backLink {
    right: 2%;
    top: -4%;
  }

}