.globalBackgroundVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -3;
  pointer-events: none;
  transition:  2s ease-in-out;
  opacity: .5;
}
.blurEffect {
  filter: blur(12px); /* Задайте необходимую степень размытия */
}

.homePageLink {
  background: white;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  width: 80px;
  border-radius: 40px;
  margin: 60px 0 0 60px;
}
.arrowLeftIcon {
  width: 30px;
  height: 30px;
}

.titleListContainer {
  display: flex;
  justify-content: space-between;
  margin: 175px 60px 0 60px;
}

.containerListTitle {
  color: var(--White, #FFF);
  font-family: Goldman;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 52.2px */
  letter-spacing: -2.32px;
  text-transform: uppercase;
  margin-top: 31px;
}

.containerCategory {
  display: flex;
  flex-wrap: wrap;
  max-width: 720px;
  width: 100%;
}

.listCategory {
  color: black;
  background: white;
  margin: 4px 4px 4px 4px;
  padding: 12px 20px 14px 20px;
  font-weight: 500;
  border-radius: 12px;
  font-size: 18px;
  line-height: 100%;
  cursor: pointer;
  letter-spacing: -0.72px;
  transition: .3s;
}

.listCategory:hover {
  background: #FF4200;
  color: white;
  transition: .3s;
}

@media screen and (max-width: 1024px) {
  .titleListContainer {
    display: block;
    margin: 125px 60px 0 60px;
  }
  .containerListTitle {
    font-size: 40px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 550px) {
  .titleListContainer {
    display: block;
    margin: 125px 20px 0 20px;
  }
  .containerListTitle {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .listCategory {
    margin: 2px 2px 2px 2px;
    padding: 10px 15px 12px 15px;
    font-size: 13px;
  }
  .homePageLink {
    margin: 60px 0 0 20px;
  }
}

@media screen and (max-width: 410px) {
  .titleListContainer {
    display: block;
   }
  .containerListTitle {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .listCategory {
    margin: 2px 2px 2px 2px;
    padding: 10px 15px 12px 15px;
    font-size: 13px;
  }
}