.videoBlock {
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 901px;
  flex: 1 1 50%; /* Обеспечивает равное распределение ширины для обоих блоков */
  max-width: 50%; /* Ограничивает максимальную ширину */
  height: 831px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  transform: translateX(2000px);
 }

.videoContainer {
  position: relative;
}

.videoBlock::before {
  opacity: .8;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../../assets/aboutSectionAssets/image/back.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

@media screen and (max-width: 1920px)  {
  //.videoBlock {
  // width: 1093px;
  //}
}

@media screen and (max-width: 1536px)  {
  //.videoBlock {
  //  width: 700px;
  //}
}

@media screen and (max-width: 1024px)  {
  //.videoBlock {
  //  width: calc(100% - 30px); /* Вычитаем margin-right и margin-left */
  //  max-width: 96%;
  //  margin-top: 10px;
  //  margin-left: 20px;
  // }
}
@media screen and (max-width: 834px)  {
  .videoBlock {
    margin: 0;
    max-width: 100%;
  }
  .videoBlock::before {
    background-image: url("../../../assets/aboutSectionAssets/image/backgMb.webp");
  }
}