.wrapperRegistration {
  min-height: calc(100vh);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.wrapperRegistration::-webkit-scrollbar {
  display: none; /* Для Webkit-браузеров */
}

.registrationContainer {
  padding: 10px 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 420px;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  margin: 0 20px;
}

.titleDescr {
  width: 100%;
  max-width: 420px;
  margin-top: 40px;
}

.titleRegistration {
  color:  white;
   font-family: Goldman;
  font-size: 40px;
  font-style: normal;
  margin-left: 17px;
  letter-spacing: -1.6px;
  text-transform: uppercase;
}

.errorEmail {
  color:  red;
  font-size: 20px;
}

.registrationTextArea{
  min-height: 151px;
  resize: none;
  padding: 20px;
  font-size: 16px;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: white;
  margin-top: 8px;
  color: black;
  &::placeholder {
    color: #C8C8C8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}

.inputName,.inputEmail,.inputPassword,.inputRepeatPassword {
  padding: 20px;
  font-size: 16px;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: white;
  color: black;
  margin-top: 8px;
  outline: none;
  &::placeholder {
    color: #C8C8C8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}
.inputName:focus,
.inputEmail:focus,
.inputPassword:focus,
.inputRepeatPassword:focus {
  outline: red;
  border: 1px solid red;
}
.containerCheckbox {
  display: flex;
  margin-top: 24px;
}

.agreement {
  margin-left: 8px;
  color:  #828282;
  & span {
    color: white;
  }
}

.enterAccount {
  color: var(--Gray, #B0B7B9);
  text-align: center;
   font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.16px;
  margin-top: 16px;
}

.linkEnter {
   display: inline-block;
   border-bottom: 1px solid var(--Gray, #B0B7B9);
}

.linkEnter:hover {
  color: white;
  border-bottom: 1px solid white;
}

.containerCheckbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #828282;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  margin-right: 10px;
  position: relative;
}

.containerCheckbox input[type="checkbox"]:checked {
  background-color: #DF450F;
  border-color:  #DF450F;
}

.containerCheckbox input[type="checkbox"]::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 5px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.containerCheckbox input[type="checkbox"]:checked::after {
  opacity: 1;
}

