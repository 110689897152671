.videoBackground {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 97px);
  overflow: hidden;

}
  .backgroundVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    transform: translate(-50%, -50%);
    object-fit: cover;
    filter: blur(20px);
  }
  .minWorldGreen {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 330px;
    height: auto;
  }

.containerGrEn {
  min-height: calc(100vh - 97px);
  display: flex;
  flex-direction: column;
   justify-content: center;
}

.wrapperGreen {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  gap: 30px;
  width: 100%;
  margin-top: 130px;
}

.dugaGrEng {
  height: 500px;
  max-width: 527px;
  width: 100%;
  position: relative;
}

.titleGreenEn {
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Goldman', sans-serif;
  font-size: 58px;
  line-height: 90%;
  position: relative;
}
.numbersGrEng {
  font-size: 62px;
  font-weight: bold;
  font-family: 'Goldman', sans-serif;
  position: absolute;
  color: white;
  left: 50%;
  top: 10%;
  transform: translateX(-50%, );
}

.btnGrEng {
  background: no-repeat url("../../assets/assetsGreenEnergy/image/BtnEng.webp");
  background-size: cover;
  position: absolute;
  bottom: 20%;
  width: 100%;
  max-width: 527px;
  height: 125px;
  color: white;
  font-size: 27px;
  font-family: "Goldman", Regular;
  text-transform: uppercase;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1920px) {
  .containerGrEn {
    margin-top: -130px;
  }
}

@media screen and (max-width: 1920px)  {
  .containerGrEn {
    margin-top: 200px;
  }
}

@media screen and  (max-width: 1536px) {
  .wrapperGreen {
    margin-top: 50px;
  }
  .dugaGrEng {
     max-width: 424px;
     width: 100%;
   }
  .btnGrEng {
     width: 100%;
    bottom: 0;
    max-width: 427px;
    height: 100px;
    font-size: 20px;
    line-height: 24px;
  }
  .minWorldGreen {
    top: 40%;
  }
  .numbersGrEng {
    top: 30%;
  }
}

@media screen and  (max-width: 1350px) {
  .containerGrEn {
    margin-top: 200px;
  }
}

@media screen and  (max-width: 1200px) {
  .dugaGrEng {
    max-width: 360px;
    width: 100%;
  }
  .btnGrEng {
    bottom: 30px;
    width: 100%;
    max-width: 427px;
    height: 85px;
    font-size: 18px;
    line-height: 80%;
  }
}

@media screen and  (max-width: 1024px) {
  .containerGrEn {
    margin: 100px 0 100px 0;
  }
  .dugaGrEng {
    max-width: 424px;
    width: 100%;
  }
  .btnGrEng {
    width: 100%;
    font-size: 22px;
    line-height: 80%;
    max-width: 427px;
    height: 100px;
  }
  .btnEnText {
    padding: 32px 39px ;
  }
  .specialStyle {
    margin-top: -50px;
  }
}

@media screen and  (max-width: 834px) {
  .dugaGrEng {
    max-width: 334px;
    width: 100%;
  }
  .btnGrEng {
    width: 100%;
    font-size: 16px;
    line-height: 80%;
    max-width: 360px;
    height: 78px;
  }
  .btnEnText {
    padding: 32px 39px ;
  }
}

@media screen and  (max-width: 697px) {
  .wrapperGreen {
    margin-top: 100px;
  }

  .dugaGrEng {
    margin-top: -102px;

  }
}

@media screen and (max-width: 698px) {
  .btnGrEng {
    left: 50%; /* Make sure it's still 50% from the left */
    transform: translateX(-50%);
    width: 335px;
    margin: 0 auto 30px auto;
    height: 79px;
    font-size: 16px;
    line-height: 80%;

    //left: 12%;
  }
  .titleGreenEn {
    font-size: 30px;
  }
}
