.wrapperDigital {
  margin: 40px 0 0 40px ;
}

.title {
  color: #FFF;
  font-family: Goldman;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 36px */
  letter-spacing: -1.6px;
  text-transform: uppercase;
}

.cardSolarPanels {
  max-width: 555px;
  border-radius: 24px;
  background: white;
  height: 241px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.wrapperBlockCard {
  padding: 24px;
  position: relative;
  height: 241px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hired {
  background: #F9A734;
  color: white;
  display: inline-block;
  padding: 6px;
  border-radius: 4px;
}

.address {
  color: #000;
  font-family: "Golos Text";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
  letter-spacing: -0.24px;
  width: 300px;
  margin-top: 4px;
}

.wrapperMessage {
  margin: 24px 24px 0 0;
  cursor: pointer;
  height: 45px;
}

.id span {
  color: rgba(0, 0, 0, 0.50);
}

.responsibleName span {
  color: rgba(0, 0, 0, 0.50);
}

.atWork {
  background-color: #F9A734;
}
.inProgress,.atWork,.completed,.waiting {
  color: white;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;

}

.completed {
  background-color: green;

}
.waiting {
  background-color: red;
}

@media (max-width: 1024px) {
  .wrapperDigital {
    margin: 20px 20px 0 20px ;
  }
}