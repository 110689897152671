@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  position: relative;
  min-height: 100vh;
}


body {
  background: #000000;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.globalBackgroundVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -3;
  pointer-events: none;
  opacity: 0;
  transition:  2s ease-in-out;

}
 .fade-video {
   animation: fadeIn 5s forwards;

}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: .8;
   }
}
.video-blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -2;
  pointer-events: none;
}
 .app-container {
  position: relative;
  z-index: 1;
}

.intro {
  position: relative;
  z-index: 2;
  color: white;
  padding: 2px;
}

.intro {
  position: relative;
  z-index: 2;
  color: white;
}
