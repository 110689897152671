.containerSlider {
    position: relative;
    padding: 4rem 1rem;
    margin: 0 auto 0 auto;
    /*animation: RightAbout 1s forwards;*/
    min-height: calc(100vh);
    overflow: hidden;
    max-width: 1920px;
}

.sliderTitle {
    color: white;
    font-size: 4rem;
    text-transform: uppercase;
    font-family: "Goldman", Regular;
}
.wrapperTitleText {
    margin-left: 60px;
}

.sliderText {
    color: white;
}

.textCatousel {
    color: white;
    font-size: 19px;
    line-height: 120%;
    height: auto;
    max-width: 533px;
    width: 100%;
}

.wrapperTitleText {
    display: flex;
    align-items: center;
}

.wrapperTitleText p {
    position: absolute;
    left: 50%;
}

.swiper_container {
    opacity: 1;
    margin: 50px auto 0 auto; ;
    padding: 2rem 0;
    position: relative;
    animation: slide 1s forwards;
    top: 50%;

}
.swiper-slide {
    background: center center no-repeat  url("../../assets/carouselAssets/image/RedBlock.webp");
    width: 500px;
    height: 680px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.prev-slide,.next-slide,.second-prev-slide,.second-next-slide {
    background: #007aff;
    background: center center no-repeat url("../../assets/carouselAssets/image/ShadovBlock.webp");
    background-size: cover;
    backdrop-filter: blur(20px);
}

.subtitleCarousel {
    display: none; /* Скрываем текст для всех слайдов */
}

.central-slide .subtitleCarousel {
    display: block; /* Показываем текст только для центрального слайда */
}
.other-slide {
    opacity: 0;
}
.other-slide .subtitleCarousel {
    display: none;
}

.swiper-slide img {
    width: 395px;
    height: 308px;
    user-select: none;
}

.titleCarousel  {
    color: white;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 36px;
    text-align: center;
    font-family: "Goldman", Regular;
}

.subtitleCarousel {
    font-size: 16px;
    color: white;
    width: 350px;
    text-align: center;
    line-height: 20px;
}

@media (max-width: 1920px) {

}

@media (max-width: 1536px) {
    .swiper-slide {
        background-size: cover;
        width: 400px;
        height: 540px;
    }
    .swiper-slide img {
        width: 297px;
        height: 234px;
        user-select: none;
    }
    .titleCarousel  {
        font-size:33px;
    }
    .subtitleCarousel {
        font-size: 14px;
    }
    .sliderController {
        bottom: 20%;
        left: 50%;
    }
}

@media (max-width: 1200px) {
    .textCatousel {
        max-width: 483px;
        width: 100%;
    }
}

@media (max-width: 950px) {
    .wrapperTitleText {
        display: block;
    }
    .wrapperTitleText p {
        position: static;
        margin-top: 16px;
        width: 300px;
    }
    .titleCarousel  {
        font-size:37px;
    }
}

@media (max-width: 550px) {
    .swiper-slide {
        width: 300px;
        height: 415px;
    }
    .swiper-slide img {
        width: 257px;
        height: 214px;
        user-select: none;
        margin-left: -25px;
    }
    .titleCarousel  {
        font-size:42px;
    }

    .subtitleCarousel {
        font-size: 12px;
    }
    .wrapperTitleText {
        display: block;
    }
    .subtitleCarousel {
        font-size: 11px;
        color: white;
        width: 270px;
        text-align: center;
        line-height: 15px;
    }

    .titleCarousel {
        font-size: 27px;
        line-height: 26px;
        margin-top: -20px;
    }
    .sliderTitle {
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 40px */
        letter-spacing: -1.6px;
        text-transform: uppercase;
    }
    .wrapperTitleText p {
        position: static;
        margin-top: 16px;
        width: 300px;
    }
    .sliderController {
        bottom: 37%;
        left: 50%;
    }
    .wrapperTitleText {
       margin: 20px 0 40px 20px;
    }
}

/*@media (max-width: 450px) {*/
/*    .slider-controler .swiper-button-next {*/
/*        left: 80% !important;*/
/*        transform: translateX(-80%) !important;*/
/*    }*/
/*}*/

/*@media (max-width: 990px) {*/
/*    .slider-controler .swiper-button-prev {*/
/*        left: 30% !important;*/
/*        transform: translateX(-30%) !important;*/
/*    }*/
/*}*/

/*@media (max-width: 450px) {*/
/*    .slider-controler .swiper-button-prev {*/
/*        left: 20% !important;*/
/*        transform: translateX(-20%) !important;*/
/*    }*/
/*}*/