
.videoContent {
  display: flex;
  position: relative;
  overflow: hidden;
}

.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: calc(100vh);
  overflow: hidden;
  z-index: -2;
  opacity: 0.5;
  filter: blur(10px);
}

#backgroundVideo{
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: -2; /* Видео на заднем фоне */
}

@media (max-width: 1536px) {

}

@media (max-width: 1024px) {
  .videoBackground,.videoContent {
    display: block;
  }
}