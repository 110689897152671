.mainProfileDropdown {
  width: 257px;
  background-color: white;
  position: absolute;
  right: 20px;
  top: 70px;
  z-index: 10;
  border-radius: 12px;
  border: 1px solid #cccaca;
 }

.avatarProfile {
  width: 40px;
  height: 40px;
  background: #1b4f72;
  border-radius: 50%;
}

.contentProfile {
  display: flex;
  align-items: center;
}
.borderBottom {
  border-top: 1px solid #E1E1E1;
  margin: 16px  0;
}

.userName {
  margin-left: 10px;
  font-weight: 500;
}

.mainSetting {
   cursor: pointer;
  margin-bottom: 10px;
}

.contentDropdown {
  padding:16px 16px 0 16px;
}
.iconTitle {
  display: flex;
  border-radius: 12px;
  padding: 10px;
  p {
    margin-left: 10px;
  }
}

.iconTitle:hover {
  background: #f2f2f3;
}