.cardSolarPanels {
  border-radius: 24px;
  background: white;
   width: 100%;
  }

.flexCardTask {
  display: flex;
  justify-content: space-between;
}

.wrapperBlockCard {
  flex: 1;
  padding: 24px 0 0 24px;
  position: relative;
  height: 201px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.address {
  color: #000;
   font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
  letter-spacing: -0.24px;
  width: 300px;
  margin-top: 4px;
}

.wrapperMessage {
  margin: 24px 24px 0 0;
   display: flex;
  flex-direction: column;
  justify-content: space-between;
 }

.id span {
  color: rgba(0, 0, 0, 0.50);
}

.responsibleName {
  display: flex;
  align-items: center;
  position: relative;
 }

.responsibleName span {
  color: rgba(0, 0, 0, 0.50);
}

.atWork {
  background-color: #F9A734;
}
.inProgress,.atWork,.completed,.waiting {
  color: white;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;

}

.completed {
  background-color: green;

}
.waiting {
  background-color: #DF450F;
}

.btn {
  margin: -5px 10px 0 10px;
}

.iconTask {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.20);
}

.iconArrow {
  font-size: 20px;
  margin-left: 1px;
  color: #DF450F;
}

@media (max-width: 500px) {
  .cardSolarPanels {
     //min-height: 301px !important;
  }
  .address {
    width: 240px;
    font-size: 20px;
  }
  .wrapperBlockCard {
   }
}

