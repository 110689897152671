.main {
  margin: 40px 40px 0 40px;
 }

.title {
  color: #FFF;
  font-family: Goldman;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 36px */
  letter-spacing: -1.6px;
  text-transform: uppercase;
}

.borderBottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  margin: 24px 0px 0 0px;
}

.containerTitleSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerInputs {
  display: flex;
  margin-top: 25px;
  align-items: center;
}

.cardCheckbox:nth-child(2) {
  margin: 0 40px ;
}

.cardCheckbox:nth-child(4) {
  margin-left: 40px ;
}

.nameCheckbox {
  color: #C8C8C8;
  font-size: 16px;
  margin-left: 6px;
  display: inline-block;
}

.containerInputs input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #828282;
  border-radius: 4px;
   cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  position: relative;
  top: 4px;
}
.containerInputs input[type="checkbox"]:checked {
  background-color: #DF450F;
  border-color:  #DF450F;
}
.containerInputs input[type="checkbox"]::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 5px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s ease;
}
.containerInputs input[type="checkbox"]:checked::after {
  opacity: 1;
}

.wrapperSearch {
  max-width: 438px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
 }

.searchIcon {
  position: absolute;
  left: 16px;
}

.search {
  width: 100%;
  background: rgba(255, 255, 255, 0.10);
  padding: 16px;
  border-radius: 16px;
}

.search::placeholder {
  padding-left: 35px; /* отступ слева */

}

.cardSolarPanels {
  max-width: 555px;
  border-radius: 24px;
  background: white;
  height: 241px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

}

.wrapperBlockCard {
  padding: 24px;
  position: relative;
  height: 241px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.address {
  color: #000;
  font-family: "Golos Text";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 31.2px */
  letter-spacing: -0.24px;
  width: 300px;
  margin-top: 4px;
}

.wrapperMessage {
  margin: 24px 24px 0 0;
  cursor: pointer;
  height: 45px;
}

.id span {
  color: rgba(0, 0, 0, 0.50);
}

.responsibleName span {
  color: rgba(0, 0, 0, 0.50);
}

.wrapperTasks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
 }

@media (max-width: 1215px) {
  .wrapperSearch {
    max-width: 245px;

  }

}

@media (max-width: 1024px) {
  .wrapperSearch {
    max-width: 400px;

  }
}

@media (max-width: 834px) {
  .main {
    margin: 20px 20px 0 20px;
  }
  .wrapperSearch {
    max-width: 250px;
  }

  .title {
    font-size: 33px;
  }
}

@media (max-width: 600px) {
  .main {
    margin: 10px 10px 0 10px;
  }

  .wrapperTasks {
    justify-content: center;
    width: 100%;
  }
  .wrapperSearch {
    max-width: 400px;
    margin-top: 20px;
  }

  .title {
    font-size: 40px;
  }

  .containerTitleSearch {
    display: block;
  }
  .containerInputs {
    display: block;
    margin-bottom: 32px;
  }
  .cardCheckbox:nth-child(2) {
    margin: 10px 0;
  }

  .cardCheckbox:nth-child(4) {
    margin: 10px 0;
  }
  .wrapperNameCheckbox {
    display: flex;
     flex-direction: row-reverse;
    justify-content: space-between;
  }
  .nameCheckbox {
    display: block;
    text-align: start;

  }

}