.dropdownContainer {
  position: relative;
  width: 100%;
}

.selectedValue {
  padding: 20px;
  font-size: 16px;
  border-radius: 16px;
  width: 100%;
  color: white;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: rgba(247, 247, 247, 0.10);
}

.dropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-radius: 16px;
  max-height: 200px;
  color: white;
  overflow-y: auto;
  z-index: 10;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: #252424;
  font-weight: 600;
  scrollbar-width: none;
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: #DF450F;
  }
}
