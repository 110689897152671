.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  backdrop-filter: blur(10px); /* Размытие фона */
  -webkit-backdrop-filter: blur(10px)
}

.wrapperBtnModal {
  cursor: pointer;
}

.titleModal {
  color: var(--White, #FFF);
  font-family: Goldman;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 52.2px */
  letter-spacing: -2.32px;
  text-transform: uppercase;
}

.backLink {
  width: 44px;
  padding: 10px;
  margin: 40px 0 0 40px;
  border-radius: 18px;
  color: white;
  background: rgba(255, 255, 255, 0.10);
  position: absolute;
  right: 5%;
  top: -5%;
  cursor: pointer;
}

.modalSubtitle {
  color: var(--White, #FFF);
  margin-top: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 20.8px */
  letter-spacing: -0.16px;
}

.modalContainer {
  padding: 20px;
  width: 100%;
  max-width: 590px;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  margin: 0 20px;
 }

.modalContainer::-webkit-scrollbar {
  display: none; /* Для Webkit-браузеров */
}

.modalForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}

.modalInput, .modalTextarea {
  padding: 20px;
  font-size: 16px;
  border-radius: 16px;
  width: 100%;
  color: white;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: rgba(247, 247, 247, 0.10);
  &::placeholder {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}


.uploadContainer,.uploadContainerFile {
  border: 2px dashed #ff4500;
  border-radius: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
  &:hover {
    border-color: #ff6347;
  }
}
.fileNameTrue {
  background: #DF450F;
  border: none;
}
.uploadLabelFile {
  display: flex;
  align-items: center;
}

.uploadLabel {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.uploadCircle,.uploadCircleFile {
  width: 32px;
  height: 32px;
  background-color: #ff4500;
  color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
 }

.uploadCircleCheck,.uploadCircleCheckWork {
  background: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploadCircleFile,.uploadCircleCheck {
 }

textarea {
  min-height: 100px;
}
.uploadText,.uploadTextFile {
  color: white;
  font-size: 16px;
}

.uploadText,.uploadTextFile {
  margin-left: 16px;
 }

.uploadInput {
  display: none;
}

.fileName,.uploadFileName {
  color: white;
  margin-left: 14px;
}

.uploadFileName {
  color: white;
  margin-top: 5px;
}


@media (max-width: 1536px) {
  .modalContainer {
      overflow-y: scroll;
  }
  .modalTextarea {
    min-height: 151px;
    padding-top: 15px;
    resize: none;
  }
  .modalSubtitleBotton {
    margin: 20px 0 10px 0 ;
  }

  .titleModal {
    font-size: 50px;
  }
  .backLink {
    top: -2%;
    right: 3%;
  }
}



@media (max-width: 500px) {
  .modalSubtitle {
    font-size: 12px;
    margin-top: 7px;
  }

  .modalSubtitleBotton {
    margin: 8px 0 8px 0 ;
  }
  .modalContainer {
    padding-bottom: 100px;
    overflow-y: scroll;

  }

  .titleModal {
    margin-top: 70px;
    font-size: 30px;
    width: 330px;
  }
  .modalForm {
    margin-top: 20px;
  }
  .modalSubtitleBotton {
    font-size: 12px;
  }
  .modalSubmit {
    padding: 15px;
  }

  .backLink {
     top: 6%;
     right: 8%;
  }
  .uploadContainer {
    margin-top: 5px;
 }
}

@media (max-width: 350px) {
  .modalContainer {
    height: 615px;
   }
}