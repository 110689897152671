
.iconFill,.iconWhiteFill {
  width: 200px;
  height: 180px;
}
.iconFill path {
  z-index: -1;
}

@media (min-width: 1921px) {
  .iconFill,.iconWhiteFill {
    width: 290px;
    height: 280px;
  }
}
@media (max-width: 1920px) {
  .iconFill,.iconWhiteFill {
    width: 200px;
    height: 180px;
  }
  .iconFill path {
    z-index: -1;
  }
}

@media only screen and (max-width: 1536px) {
  .iconFill,.iconWhiteFill {
    width: 180px;
    height: 140px;
  }
}

@media (max-width: 1200px) {
  .iconFill,.iconWhiteFill {
    width: 160px;
    height: 135px;
  }
}

@media (max-width: 1024px) {
  .iconFill{
    width: 160px;
    height: 130px;
  }
  .iconWhiteFill {
    width: 140px;
    height: 118px;
  }
}

@media (max-width: 500px) {
  .iconFill {
    width: 120px;
    height: 110px;
  }
  .iconWhiteFill {
    width: 110px;
    height: 100px;
  }
}

@media (max-width: 400px) {
  .iconFill {
    width: 95px;
    height: 85px;
  }
  .iconWhiteFill {
    width: 93px;
    height: 90px;
  }

}
