.containerImagesCategory {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;
}

.containerImagesCategory::before {
  content: "";
  display: block;
  border-top: 1px solid gray;
  width: 100%;
  margin: 40px 0 20px 0;
}

.imagesContainer {
  background: center center no-repeat url("../../../assets/listDestinationsAssets/ShadovBlock.svg");
  background-size: contain;
   height: 622px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.imagesContainer img {
  width: 339px;
  height: 268px;
  user-select: none;
}
.titleListCategory  {
  color: white;
  text-transform: uppercase;
  font-size:40px;
  font-family: "Goldman", Regular;
}
.subtitleCategory {
  font-size: 14px;
  color: white;
  width: 359px;
  text-align: center;
  line-height: 20px;
  opacity: 0;
 }
.imagesContainer:hover {
  background: center center no-repeat url("../../../assets/listDestinationsAssets/RedBlock.svg");
  background-size: contain;
  transition: .3s;
}

.imagesContainer:hover .subtitleCategory {
  transition: .3s;
  opacity: 1;
}

//@media screen and (max-width: 1920px) {
//  .imagesContainer {
//    width: 455px;
//    height: 622px;
//  }
//}

@media screen and (max-width: 1820px) {

}


//@media screen and (max-width: 1020px) {
//  .imagesContainer:hover  {
//    width: 430px;
//    height: 588px;
//    transition: none;
//  }
//}