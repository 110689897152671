.wrapperContainerConfirmation {
}
.containerConfirmation {
  max-width: 546px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
   transform: translate(-50%,-50%);

  padding: 0 15px;
}

.title {
  color: #FFF;
  text-align: center;
  font-family: Goldman;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 36px */
  letter-spacing: -1.6px;
  text-transform: uppercase;
}

.text {
  color: #FFF;
  text-align: center;
  font-family: "Golos Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.16px;
  margin: 16px 0 0 0;
}

.imageStatus {
  width: 305px;
  margin: 0 auto 54px auto;
}

@media  (max-width: 550px) {
  .title {
    font-size: 28px;
  }
}