.sidebar {
  width: 370px;
 }

.wrapperLinks {
  margin: 40px 40px;
}

.navSidebar {
  display: flex;
  margin-top: 16px;
  padding: 16px;
  border-radius: 16px;
  transition: .3s;
  color: rgba(255, 255, 255, 0.20);
}

.iconSidebar svg path  {
  fill: rgba(255, 255, 255, 0.20);
}

.linkTitle {
   font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
  letter-spacing: -0.18px;
  margin-left: 12px;
}

.active {
  background: rgba(255, 255, 255, 0.10);
  cursor: pointer;
}

.activeTitle {
  color: white;
}

.sidebar {
  transition: transform 0.3s ease;
 // Скрыть sidebar по умолчанию
}

.sidebarVisible {
  }



@media (max-width: 1024px) {
  .sidebar {
    display: none;
  }
  .sidebarVisible {
    display: block;
   }

  .sidebarMobile {
    margin: 50px 0 ;
  }

}