.header {
  position: fixed;
  top: -100px;
  left: 0;
  right: 0;
  color: #fff;
  backdrop-filter: blur(4px);
  transition: top 0.3s ease;
  z-index: 1000;
}

.liHeader {
  margin-left: 16px;
}


.headerVisible {
  top: 0;
}

.containerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 26px;
  position: relative;
  }

.menuIcon {
  display: none;
}

.navHeaderLink {
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.navHeaderLink:hover {
  color:  #FF2E00;;
}

.navBtnWrapper {
  width: 87%;
  display: flex;
  justify-content: space-between;
  align-items: center;
 }

.navListHeader {
  width: 700px;
  margin: auto;
  display: flex;
  justify-content: end;
}

.buttonContainer {
  position: relative;
  display: inline-block;
  border-radius: 14px;
  transition: .3s;
  z-index: 10;

}

.button {
  border: none;
  width: 176px;
  height: 50px;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: .3s;
}
.button:hover {
  color: #FF2E00;
  transition: .3s;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;

  &.top-left {
    top: 10px;
    left: 10px;
  }
  &.top-right {
    top: 10px;
    right: 10px;
  }
  &.bottom-left {
    bottom: 10px;
    left: 10px;
  }
  &.bottom-right {
    bottom: 10px;
    right: 10px;
  }
}
.borderBottomHeader {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.mobileMenuContent {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #000000;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  z-index: 10;
}

.mobileMenuLinks {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobileMenuLinks li a {
  display: block;
  padding: 1rem 0;
  color: white;
  text-decoration: none;
}

.mobileMenuLinks li a:hover {
  background-color: #555;
}

.wrapperDropDown {
  display: none;
}

.closeButton {
  width: 66px;
  height: 66px;
  flex-shrink: 0;
  background: rgba(247, 247, 247, 0.10);
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 15px;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #FFFFFF;
  display: none;
}

.wrapperBtn {
  display: flex;
 }
.buttonOrange:hover {
  color: black;
}


@media screen and (max-width: 1200px) {
  .navListHeader {
    width: 500px;

  }
}

@media screen  and (max-width: 1030px) {
  .containerMb {
    min-height: calc(100vh);
    background-color: #000000;
  }
  .main-nav,
  .user-actions{
    display: none;
  }

  .wrapperBtn {
    margin-left: 8px;
    position: absolute;
    left: 0;
    right: 2px;
    bottom: calc(20px + env(safe-area-inset-bottom));
    width: 100%;
  }
.buttonContainer {
  width: 95%;
  text-align: center;
  margin-right: 0 !important;
}
  .navBtnWrapper {
    display: block;
  }
  .btnHeader {
    display: none;
    &.btnActive {
      display: block;
      width: 100%;
    }
  }
  .menuIcon {
    display: block;
    cursor: pointer;
    color: white;
    &.menuIconActive {
      display: none;
    }
  }
.containerMb {
  display:  block;
}

  .liHeader {
    margin: 16px 0 0 0;
  }

  .navHeader {
    display: none;
    &.active {
      display: block;
      color:white;
      margin-top:100px ;
    }
  }

.listHeaderActive {
  display: block;
  margin: 0;
}
  .activeCloseButton{
    display: block;
  }

}

@supports (-webkit-touch-callout: none) {
  .buttonContainer {
    bottom: calc(120px + env(safe-area-inset-bottom));
  }
}

@media (max-width: 450px) {
  .wrapperBtn {
    margin: 0 5px;
    position: absolute;
    left: 0;
    right: 2px;
    bottom: calc(20px + env(safe-area-inset-bottom));
    width: 100%;
  }
  .buttonContainer {
    width: 95%;
    text-align: center;
    margin-right: 0 !important;
  }
}