.aboutUsSection {
  min-height: calc(100vh - 97px);
  position: relative;
  overflow: hidden;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  box-sizing: border-box;
}


.mainContent {
  height: 330px;
}
.wrapperAbout {
  flex: 1 1 50%;
  max-width: 50%;
  position: relative;
  padding-top: 20px;
  width: 901px;
  height: 831px;
  z-index: 1;
  opacity: 0;
  transform: translateX(-2000px);
}

.wrapperAbout::before {
  opacity: .8;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../assets/aboutSectionAssets/image/back.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
  transform: rotate(-180deg);
}
.header h1 {
  font-family: 'Goldman';
  text-transform: uppercase;
  font-size: 58px;
  margin: 0px 60px 0 60px;
  color: #FFFFFF;
}
.containerAbout {
  box-sizing: border-box;
  border-radius: 20px;
  margin: 0 60px  0 60px;
}

.textBlock {
  display: inline-flex;
  color:white;
  font-size: 18px;
  border-radius: 20px;
}
.textBlockTwo,.textOne {
  width: 360px;
  max-width: 100%;
}

.textBlockTwo {
  margin-left: 20px;
}

.sectionIndustrial {
  color: #FFF;
  font-family: Goldman;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 90%;
  letter-spacing: -0.56px;
  text-transform: uppercase;
  border-radius: 7px;
  text-align: center;
  background: #DF450F;
  padding: 10px;
  margin-top: 40px;
  width: 285px;
}

.barCodeClass {
  margin-top: 5px;
}

.infoContainer {
  width: 700px;
  max-width: 100%;
  display: flex;
}

.iconsContainer {
  display: flex;
  border-radius: 50px;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 0px 0px 2.167px 0px rgba(255, 255, 255, 0.12) inset;
  backdrop-filter: blur(27.083335876464844px);
  margin-top: 50px;
  margin-left: 96px;
  padding: 10px 10px;
}

.iconsContainer img {
  width: 44px;
  height: 44px;
}

.iconMin3D {
  width: 63px;
  height: 63px;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.iconVerticalBlock {
  margin: 0 20px;
  padding: 10px 9px;
}

.iconSquare {
  margin-right: 4px;
}

.icon3D {
  animation: rotateEarth 10s linear infinite;
}

@keyframes rotateEarth {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1920px) {
  .textBlock {
    font-size: 20px;
  }
}

@media screen and (max-width: 1920px)  {
  .textBlockTwo,.textOne {
    width:  320px;
    max-width: 100%;
  }
  .textBlockTwo {
    margin-left: 16px;
    margin-right: 26px;
  }

  .iconsContainer {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1536px)  {
  .mainContent {
    height: 230px;
  }
  .textBlock {
   display: block;
  }
  .textBlockTwo {
    margin: 5px 0 0 0;
  }
  .textBlockTwo,.textOne {
    width:  500px;
    max-width: 100%;
  }
  .infoContainer {
    flex-direction: column-reverse
  }
  .iconsContainer {
    margin: 20px 0;
    width: 351px;
  }
  .sectionIndustrial {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1200px) {
  .header h1 {
    font-size: 45px;
  }
  .containerAbout {
    max-width: none;
    margin: 0 20px 0 35px;
    position: relative;
    bottom: 0;
  }
}

@media screen and (max-width: 1024px)  {
  .textBlock {
    display: block;
    font-size: 16px;
  }
  .textBlockTwo {
    margin: 10px 0 0 0;
  }

  .header h1 {
    font-size: 36px;
    margin: 0 60px  0 30px;
  }

  .containerAbout {
    max-width: none;
    margin: 0 20px 0 25px;
    position: relative;
    bottom: 0;
  }
  .iconsContainer {
    margin: 40px 0 10px 1px;
  }
  .barcodeBlock {
    margin-left: 2px;
  }
  .mainContent {
    height: 265px;
  }

}

@media screen and (max-width: 834px)  {
  .wrapperAbout {
    max-width: 100%;
    border: none;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }
  .aboutUsSection {
    flex-direction: column-reverse;
    margin-top: 50px;
  }
  .header h1 {
    margin: 0px 0px 0 30px;
    font-size: 30px;
  }
  .textBlock {
    font-size: 16px;
  }

  .sectionContainerInfo {
    flex-wrap: wrap;
  }
  .iconsContainer {
    margin: 40px 0 0 0px;
  }
  .sectionIndustrial {
    margin-top: 15px;
  }
  .wrapperAbout::before {
    background-image: url("../../assets/aboutSectionAssets/image/backgMb.webp");

  }
  .textBlockTwo,.textOne {
    width:  345px;
    max-width: 100%;
  }
  .containerAbout {
    width: 350px;
  }
  .infoContainer {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 660px)  {
    .wrapperAbout {
      display: block;
    }
  .mainContent {
    height: 40px;
  }
  .textBlock {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .iconsContainer {
    display: flex;
    border-radius: 50px;
    padding: 5px 5px;
    width: 275px;
  }
  .textBlockTwo,.textOne {
    width:  245px;
    max-width: 100%;
  }
  .iconVerticalBlock {
    margin: 0 5px;
    padding: 3px 3px;
  }

  .iconsContainer img {
    width: 43px;
    height: 43px;
  }
  .containerAbout {
    width: initial;
  }
  .sectionIndustrial {
    font-size: 12px;
    width: 271px;
  }
  .iconMin3D {
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}
