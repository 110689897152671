.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  backdrop-filter: blur(10px); /* Размытие фона */
  -webkit-backdrop-filter: blur(10px)
}

.wrapperBtnModal {
  cursor: pointer;
}

.titleModal {
   font-family: Goldman;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 52.2px */
  letter-spacing: -2.32px;
  text-transform: uppercase;
  padding: 20px 0 20px 20px;
}

.backLink {
  width: 44px;
  padding: 10px;
  margin: 40px 0 0 40px;
  border-radius: 18px;
  color: black;
  background: rgba(255, 255, 255, 0.10);
  position: absolute;
  right: 1%;
  top: -6%;
  cursor: pointer;
}


.modalContainer {
  background: white;
  border-radius: 20px;
  width: 100%;
  max-width: 590px;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  margin: 0 20px;
}

.borderBottom {
  border-bottom: 1px solid #E1E1E1;
}
.borderBottom:nth-child(2) {
  border-bottom: 1px solid #E1E1E1;
  margin: 28px 20px ;
}

.blockBtn {
  display: flex;
  margin: 0;
}
.btn {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.dataCheck {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Отступы между элементами */
  padding: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dataBlock {
  margin: 20px 30px;
}

.dataItem {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  color: #333;
  margin-top: 25px;
}

.dataItem strong {
  font-weight: 400;
  margin-right: 0.5rem;
}

.dataItem {
  color: rgba(0, 0, 0, 0.50);
}

.dataItem span {
  color: black;
}

.blockSpan {
  width: 50%;
}

 .spanComment {
   background: #F0F0F0;
   padding: 12px;
   border-radius: 10px;
}
.borderBottomTwo {
  border-bottom: 1px solid #E1E1E1;
  margin: 28px 0px ;
}
.dataInputPlan {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.50);
}
.blockInput {
  width: 50%;
  background: #FAFAFA;
  border-radius: 12px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
 }

.blockInput span {
  display: inline-block;
  font-size: 14px;
  margin-left: 15px;
}

.icon {
  margin-right: 15px;
}

.image {
  width: 400px;
}

@media (max-width: 1536px) {
  .modalContainer {
    width: 80%;
   }

}

@media (max-width: 834px) {
  .modalContainer {
    width: 80%;
    }

}


@media (max-width: 600px) {
  .modalContainer {
    width: 90%;
    height: auto;
   }
  .dataBlock {
    margin: 20px;
  }
  .titleModal {
    font-size: 40px;
   }
  .dataItem {
    display: block;
    margin-top: 10px ;
  }
  .dataItem span {
    display: block;
  }
  .blockBtn {
    display: block;
  }
  .btn {
    margin: 5px 0 5px 5px;
    width: 97%;
  }
  .borderBottom:nth-child(2) {
    border-bottom: 1px solid #E1E1E1;
    margin: 18px 4px ;
  }
  .dataInputPlan {
    margin: 10px 20px;
  }
}




@media (max-width: 350px) {
  .modalContainer {
  width: 80%;
  }
}