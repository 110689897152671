.footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  font-family: 'Golos Text', sans-serif;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
 }


@media (max-width: 500px ) {
    .footer{font-size: 12px;
}
}