.containerFinished {
  display: flex;
  flex-direction: column;
   margin-top: 20px;
  height: 100%;
}

.div {
  height: 100%;
  margin:  0 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.title {
  color: #FFF;
  font-family: Goldman;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 36px */
  letter-spacing: -1.6px;
  text-transform: uppercase;
  margin: 0 0 20px 40px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 20px;
   }

.column {
  position: relative;
}

.column:nth-child(2):before  {
  content: "";
  position: absolute;
  border-right: 1px solid rgba(255, 255, 255, 0.10);
  height: 100%;
  right: -25px;
  top: -20px;
}
.column:nth-child(1):before  {
  content: "";
  position: absolute;
  top: -20px;
  border-right: 1px solid rgba(255, 255, 255, 0.10);
  height: 100%;
  right: -25px;
}



.columnTitle {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: white;
  }

