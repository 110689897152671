.PrivacyWrapper {
  min-height: calc(100vh - 97px);
  margin-top: 99px;
  display: flex;
}

.contentText {
  margin: 20px 0 40px 0;
}

.contentText ul {
  display: list-item;
  list-style-type: disc;
}
.textTitlePrivacy {
  padding: 40px 0 0 40px;
  box-sizing: border-box;
  width: 50%;
  height: auto;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.informationPrivacy {
  width: 50%;
  padding-top: 40px;
  padding-left: 20px;
  box-sizing: border-box;
 }

.containerInformation {
  margin-left: 20px;
 }

.itemText {
  margin-left: 8px;
 }

.itemTitle {
  color: #FFF;
  font-family: Goldman;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 28.8px */
  letter-spacing: -1.28px;
  text-transform: uppercase;
}

.titlePrivacy {
  color: var(--White, #FFF);
  font-family: Goldman;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 58px */
  letter-spacing: -2.32px;
  text-transform: uppercase;
}

.textPrivacy ,.itemsListItem,.itemSubText {
  color: var(--White, #FFF);
   font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.16px;
  max-width: 406px;
 }

.textPrivacy {
  margin-top: 20px;
}

.itemText {
  color: var(--White, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.16px;
 }



.itemsListItem {
  margin-left: 35px;
}

.emailPrivacy {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.16px;
  color: var(--White, #FFF);
  margin-left: 10px;
  display: inline-block;
  margin-top: 10px;
}

.textNote {
  color: var(--White, #FFF);
   font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.16px;
}

.contactEmail {
  color: #DF450F;
  border-bottom: 1px solid #DF450F;
  cursor: pointer;
  margin-left: 5px;
}

.textNote {
  margin-top: 15px;
  margin-left: 10px;
}

@media (max-width: 1024px) {

  .PrivacyWrapper {
    display: block;
  }
  .textTitlePrivacy {
    border-right: none;
    padding: 20px 20px 20px 20px;

  }
  .informationPrivacy,.textTitlePrivacy {
    width: 100%;
  }
  .informationPrivacy {
    border-top:1px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
  }
  .textPrivacy {
    padding-bottom: 40px;
    margin: 20px 10px 0 10px;
    max-width: 100%;
  }
  .contentText {
    margin: 20px 15px 40px 0px;
  }
  .containerInformation {
    margin: 0;
  }
}

@media (max-width: 510px) {
  .titlePrivacy {
    font-size: 37px;
    margin-bottom: 20px;
  }
  .itemTitle {
    margin-right: 10px;
    font-size: 28px;
  }
}