.neon-line {
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke-dasharray 0.3s;
  stroke-dasharray: 0, 500; /* Линия скрыта изначально */
  animation: drawLineTwo 2s ease forwards;
 }

.neon-line.hovered {
  stroke-width: 2;
  stroke-dasharray: 500, 0;
  animation: drawLine 3s ease forwards;
}




.neon-dot {
  position: relative;
  animation: moveDot 2s ease forwards;
}

/* Анимация для рисования линии */
@keyframes drawLine {
  0% {
    stroke-dasharray: 0, 500;
  }

  100% {
    stroke-dasharray: 500, 0;
  }
}

@keyframes drawLineTwo {
  from {
    stroke-dasharray: 500, 0;
  }
  to {
    stroke-dasharray: 0, 500;
  }
}

/* Анимация движения для точки */

