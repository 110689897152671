.listProduct {
  display: flex;
  margin:36px 19px ;
}

.titleProfile {
  color: #FFF;
  font-family: Goldman;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 36px */
  letter-spacing: -1.6px;
  text-transform: uppercase;
  margin: 40px 0 0 40px;
}
.imageType {
  border-radius: 16px;
  }
.cardProduct {
  margin:0 10px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 }

.containerImagePr {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;

}

.productTitle {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 24px */
  letter-spacing: -0.96px;
  margin-top: 20px;
}

@media (max-width: 1536px) {
  .titleProfile {
    margin: 20px 0 0 20px;
  }
  .cardProduct {
     margin: 10px 10px  10px 10px;
  }
  .listProduct {
    margin:25px 13px ;
  }
}

@media (max-width: 1200px) {
  .titleProfile {
    text-align: center;
  }
    .listProduct {
      flex-wrap: wrap;
      justify-content: center;
    }
  .cardProduct {
    margin: 20px 10px;
  }
}

@media (max-width: 500px) {

}