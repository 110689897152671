.wrapperRombContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 200px auto 0 auto;
  min-height: calc(100vh - 97px);
  position: relative;
  overflow: hidden;

}

.titleProblems {
  color: white;
  text-align: center;
  font-family: Goldman;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 52.2px */
  letter-spacing: -2.32px;
  text-transform: uppercase;
}

.contentBlockProblems {
  max-width: 1650px;
  width: 100%;
  display: grid;
  margin: auto;
  grid-template-columns: repeat(3, 550px);
  justify-content: center;
 }
.contentBlockProblems > :nth-child(1),
.contentBlockProblems > :nth-child(2) {
  grid-column: span 1; /* Place these in the first row */
  transform: translateX(-52%) translateY(21%); ;

}
.contentBlockProblems > :nth-child(3) {
  transform: translateX(10px);
}
.contentBlockProblems > :nth-child(3),
.contentBlockProblems > :nth-child(4),
.contentBlockProblems > :nth-child(5) {

}

.contentBlockProblems > :nth-child(5) {
  transform: translateX(-10px);
}

.contentBlockProblems > :nth-child(6),
.contentBlockProblems > :nth-child(7) {
  grid-column: span 1;
  transform: translateX(-52%) translateY(-21%);
}

.contentBlockProblems > :nth-child(7) {
  transform: translateX(-53%) translateY(-21%);
}

.contentBlockProblems > :nth-child(6), {
  transform: translateX(-51%) translateY(-21%);
}

.contentBlockProblems > :nth-child(1) {
  grid-column-start: 2;
}

.contentBlockProblems > :nth-child(6) {
  grid-column-start: 2;
}
.cardBlock {
  background: no-repeat url("../../assets/problemsAssets/image/BackrRomb.webp");
  background-size: cover;
  position: relative;
  width: 526px;
  height: 593px;
  flex-shrink: 0;
  z-index: 1;
}

.cardBlock svg:hover {
  fill: red;
}
.cardIdRombik {
  position: absolute;
  left: 50.5%;
  top: 32%;
  transform: translateX(-55%,);
  color: #FFF;
  text-align: center;
  font-family: Goldman;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 80%;
  letter-spacing: -2.24px;
  text-transform: uppercase;
}
.cardRombTextTitle {
  position: absolute;
  transform: translate(-50%);
  top: 47%;
  left: 50%;
  text-align: center;
  z-index: 0;
  max-width: 400px;
  width: 100%;
  }

.titleCardRombik {
  line-height: 25px;
  letter-spacing: -1px;
  font-size: 24px;
  font-family: "Goldman", Regular;
  text-transform: uppercase;
  color: white;
}

.textCardRombik {
   text-align: center;
  color: white;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.16px;
  margin-top: 13px;
}

@media screen and  (max-width: 1920px)  {
  .wrapperRombContent {
     margin-top: 200px;
  }
  .cardIdRombik {
    top: 31.5%;
    transform: translateX(-60%)
  }
  .cardBlock {
    width: 490px;
    height: 556px;
  }
  .cardRombTextTitle {
     top: 47%;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
    z-index: 0;
    max-width: 370px;
    width: 100%;
  }
  .titleCardRombik {
    line-height: 25px;
    letter-spacing: -1px;
    font-size: 24px;
    font-family: "Goldman", Regular;
    text-transform: uppercase;
    color: white;
  }

  .textCardRombik {
    text-align: center;
    color: white;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.16px;
    margin-top: 13px;
  }

  .contentBlockProblems {
    grid-template-columns: repeat(3, 500px);
   }

  .contentBlockProblems > :nth-child(3),
  .contentBlockProblems > :nth-child(4),
  .contentBlockProblems > :nth-child(5) {
    grid-column: span 1;transform: translateX(-0.5%) translateY(-0.5%) ;
  }

  .contentBlockProblems > :nth-child(6),
  .contentBlockProblems > :nth-child(7) {
     transform: translateX(-51.5%) translateY(-22.5%); ;
  }

  /* Adding empty spaces for alignment */

}

@media screen and  (max-width: 1536px) {
  .contentBlockProblems {
    grid-template-columns: repeat(3, 400px);
  }

  .contentBlockProblems > :nth-child(3),
  .contentBlockProblems > :nth-child(4),
  .contentBlockProblems > :nth-child(5) {
    grid-column: span 1; /* Place these in the second row */
    transform: translateX(0%);
  }
  .contentBlockProblems > :nth-child(6),
  .contentBlockProblems > :nth-child(7) {
    transform: translateX(-52%) translateY(-20%);
  }

  .cardBlock {
    width: 387px;
    height: 434px;
  }
  .titleCardRombik {
    line-height: 20px;
    letter-spacing: -1px;
    font-size: 20px;
    font-family: "Goldman", Regular;
    text-transform: uppercase;
    color: white;
  }

  .textCardRombik {
    text-align: center;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.16px;
    margin-top: 8px;
  }
  .cardRombTextTitle {
    top:47%;
    max-width: 310px;

  }
  .cardIdRombik {
    top: 31%;
    font-size: 45px;
  }
}

@media screen and  (max-width: 1200px) {
  .cardBlock {
    width: 449px;
    height: 506px
  }
  .contentBlockProblems > :nth-child(6),
  .contentBlockProblems > :nth-child(7),
  .contentBlockProblems > :nth-child(5)  {
    display: none;
  }
  .contentBlockProblems {
    width: 100%;
    display: grid;
    margin: auto;
    grid-template-columns: repeat(4, 380px);
    justify-content: center;
  }

  .contentBlockProblems > :nth-child(1) {
    grid-area: 1 / 3 / 2 / 4;

  }
  .contentBlockProblems > :nth-child(2) {
    grid-area: 2 / 2 / 3 / 3;
    transform: translateX(-20%);
  }
  .contentBlockProblems > :nth-child(3) {
    grid-area: 2 / 3 / 3 / 3;
  }
  .contentBlockProblems > :nth-child(4) {
    grid-area: 3 / 3 / 4 / 4;
    transform: translateX(-52%) translateY(-21%);
  }
  .cardRombTextTitle {
    position: absolute;
    transform: translate(-50%);
    top: 47%;
    left: 50%;
    text-align: center;
    z-index: 0;
    max-width: 350px;
    width: 100%;
  }

  .titleCardRombik {
    line-height: 20px;
    letter-spacing: -1px;
    font-size: 20px;
    font-family: "Goldman", Regular;
    text-transform: uppercase;
    color: white;
  }

  .textCardRombik {
    text-align: center;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.16px;
  }
  .cardIdRombik {
    top: 32%;
    transform: translateX(-50%);
    left: 50%;
    font-size: 45px;
  }

}
@media screen and  (max-width: 950px) {

  .cardBlock {
    width: 449px;
   }

  .contentBlockProblems {
    grid-template-columns: repeat(5, 1fr);
     margin-top: 60px;
   }

  .contentBlockProblems > :nth-child(1) {
    grid-area: 1 / 3 / 2 / 4;
    transform: translateX(0%);

  }
  .contentBlockProblems > :nth-child(2) {
    grid-area: 2 / 3 / 3 / 4;
    transform: translateX(0);
    margin: 20px 0 20px 0;
  }
  .contentBlockProblems > :nth-child(3) {
    grid-area: 3 / 3 / 4 / 4;
  }
  .contentBlockProblems > :nth-child(4) {
    grid-area: 3 / 3 / 4 / 4;
    transform: translateX(-52%) translateY(-21%);
    display: none;
  }
  .cardRombTextTitle {
    position: absolute;
    transform: translate(-50%);
    top: 47%;
    left: 50%;
    text-align: center;
    z-index: 0;
    max-width: 350px;
    width: 100%;
  }

  .titleCardRombik {
    line-height: 20px;
    letter-spacing: -1px;
    font-size: 20px;
    font-family: "Goldman", Regular;
    text-transform: uppercase;
    color: white;
  }

  .textCardRombik {
    text-align: center;
    color: white;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.16px;
    margin-top: 4px;
  }

  .cardIdRombik {
    top: 32%;
    transform: translateX(-50%);
    left: 50%;
    font-size: 45px;
  }


}


@media screen and  (max-width: 500px) {
  .contentBlockProblems {
    margin-top: 40px;
   }
  .cardBlock {
    width: 318.145px;
    height: 362px;
  }
  .titleCardRombik {
     font-size: 15px;

  }

  .textCardRombik {
    font-size: 13px;
    width: 250px;
    letter-spacing: -0.14px;
    margin: 5px auto 0 auto;
  }
  .titleCardRombik {
    line-height: 18px;
    letter-spacing: -1px;
    font-size: 20px;
    font-family: "Goldman", Regular;
    text-transform: uppercase;
    color: white;
    width: 300px;
    margin: auto;
  }
  .cardIdRombik {
    font-size: 4px;
  }

  .titleProblems {
    font-size: 38px;
  }

  .cardIdRombik {
    top: 31%;
    font-size: 38px;
    left:50%;
    transform: translateX(-50%);
  }
}






