.blackBack {
  min-height: calc(100vh - 97px);
  background-image: url("../../assets/technologyAssets/icon/Ellipse.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 50px;
  backdrop-filter: blur(27px);
  padding-bottom: 150px;
}

.animationBorder {
  position: absolute;
  top: 38.5%;
  transform: rotate(180deg);
  left: 9%;
  width: 400px;
}

.svgBorderDirect {
  position: absolute;
  width: 400px;
  height: 180px;
  z-index: 1;
}

.technologyBorder:nth-child(1) .animationBorder {
  top: 99%;
  transform: rotate(180deg);
  left: 39%;
  width: 500px;
  z-index: -1;
}
.technologyBorder:nth-child(2) .animationBorder {
  top: 143%;
  transform: rotate(270deg);
  left: -39%;
  width: 500px;
  z-index: -1;
}
.technologyBorder:nth-child(3) .animationBorder {
  top: 99.5%;
  transform: rotate(180deg);
  left: 39%;
  width: 500px;
  z-index: -1;
}
.technologyBorder:nth-child(5) .animationBorder {
  top: 99%;
  transform: rotate(180deg);
  left: 39%;
  width: 500px;
  z-index: -1;

}
.technologyBorder:nth-child(6) .animationBorder {
   transform: rotate(-90deg) scaleY(1) translateY(37%) translateX(-103%);
  left: -25%;

  width: 400px;
  z-index: -1;
}
.technologyBorder:nth-child(7) .animationBorder {
  top: 99%;
  transform: rotate(180deg);
  left: 39%;
  width: 500px;
  z-index: -1;
}
.technologyGrid {
  display: grid;
  grid-template-columns: repeat(5, 450px);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;
  justify-content: center; /* Горизонтальное центрирование */
  align-content: center;
  position: relative;
}


.titleTechn {
  color: var(--White, #FFF);
  text-align: center;
  font-family: Goldman;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 52.2px */
  letter-spacing: -2.32px;
  text-transform: uppercase;
  padding: 50px;
 }
.technologyBorder {
  background-image: url("../../assets/technologyAssets/icon/download (29).webp");
  width: 386.5px;
  height: 371px;
  background-size: cover;
  position: relative;
  z-index: 1;

 }
.technologyBorder:nth-child(1)  {
  grid-area: 1 / 2 / 2 / 3;
  transform:   translateY(110px);
}
.technologyBorder:nth-child(1) .titleTechnology {
   margin: 50px 0 0 35px;
}
.technologyBorder:nth-child(1) .imageRomb {
  position: absolute;
  right: 92.5%;
  top: 0px;
}
.technologyBorder:nth-child(1) .textTechnology {
  margin: 0 0 0 35px;
  position: absolute;
  top: 35%;
}
.technologyBorder:nth-child(2) {
  grid-area: 1 / 3 / 2 / 4;
  background-image: url("../../assets/technologyAssets/icon/download (21).webp");
  width: 363.5px;
  height: 348px;
}
.technologyBorder:nth-child(2) .imageRomb {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0px;
}
.technologyBorder:nth-child(2) .titleTechnology {
  text-align: center;
  margin: 60px auto 0 auto;
}
.technologyBorder:nth-child(2) .textTechnology {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 32%;
  text-align: center;
}
.technologyBorder:nth-child(3) {
  grid-area: 1 / 4 / 2 / 5;
  transform: scaleX(-1)  translateY(110px);
 }
.technologyBorder:nth-child(3) .titleTechnology {
  margin: 70px 0 0 35px;
  transform: scaleX(-1);
  text-align: end;
}
.technologyBorder:nth-child(3) .textTechnology {
  position: absolute;
  transform: scaleX(-1);
  top: 34.5%;
  text-align: end;
  margin: 0 0 0 35px;
}
.technologyBorder:nth-child(3) .imageRomb {
  position: absolute;
  right: 92.5%;
  top: 0px;
}
.technologyBorder:nth-child(4) {
  grid-area: 2 / 3 / 3 / 4;
  background-image: url("../../assets/technologyAssets/icon/image4.webp");
  width: 283px;
  height: 284px;
  flex-shrink: 0;
  margin: 100px 0 100px 0;
  z-index: 10;
 }
.technologyBorder:nth-child(5) {
  grid-area: 3 / 2 / 4 / 3;
  transform: scaleY(-1) translateY(110px);
  display: flex;
}
.technologyBorder:nth-child(5) .imageRomb {
  position: absolute;
  right: 92.5%;
  top: 0px;
}
.technologyBorder:nth-child(5) .titleTechnology {
  transform:  scaleY(-1);
   margin: 0 0 40px 26px;

 }
.technologyBorder:nth-child(5) .textTechnology {
  transform:  scaleY(-1);
  position: absolute;
  margin: 0 0 0 26px;
  top: 52%;
}
.technologyBorder:nth-child(6) {
  grid-area: 3 / 3 / 4 / 4;
  background-image: url("../../assets/technologyAssets/icon/download (21).webp");
  width: 363.5px;
  height: 348px;
  transform:  scaleY(-1);

 }
.technologyBorder:nth-child(6) .titleTechnology {
  transform: translate(-50%) scaleY(-1);
  text-align: center;
  position: absolute;
  top: 80%;
  left: 50%;

}
.technologyBorder:nth-child(6) .textTechnology {
  transform:translate(-50%)  scaleY(-1);
  text-align: center;
  margin: 58px  0 0 0;
  position: absolute;
  top: 40%;
  left: 50%;
}
.technologyBorder:nth-child(6) .imageRomb {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.technologyBorder:nth-child(7) {
  grid-area: 3 / 4 / 4 / 5;
  transform:  scaleX(-1) scaleY(-1) translateY(110px);
  display: flex;

}
.technologyBorder:nth-child(7) .titleTechnology {
  transform: scaleX(-1) scaleY(-1);
  margin: 0 0 40px 40px;
  text-align: end;

}
.technologyBorder:nth-child(7) .textTechnology {
  transform:  scaleY(-1) scaleX(-1);
  position: absolute;
  margin: 0 0 0 40px;
  top: 46%;
  text-align: end;
}
.technologyBorder:nth-child(7) .imageRomb {
  position: absolute;
  right: 92.5%;
  top: 0px;
}
.titleTechnology {
  color: #FFF;
  font-family: Goldman;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 28.8px */
  letter-spacing: -1.28px;
  text-transform: uppercase;
 }
.textTechnology {
  color: #FFF;
   font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  letter-spacing: -0.64px;
  width: 225px;
}
.imageRomb svg   {
  color:#A4FFA7;
  transition: color 0.3s ease; /* добавляем плавный переход для цвета */
}

.imageLogo {
  position: absolute;
  left: 75px;
  top: 75px;
  width: 36px;
}
.imageLogoWrapper {
  width: 366px;
  height: 366px;
  fill: rgba(255, 255, 255, 0.10);
  stroke-width: 1px;
  stroke: #FFF;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border-radius: 16px;
}
.imageLogoWrapper::before {
  content: "";
   width: 10px;
  height: 10px;
  top: 73px;
  right: 69px;
  background: black;
  position: absolute;
  transform: translate(-50%);
  border-radius: 16px;
}
.imageWorld {
  position: absolute;
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
  background: transparent;
  width: 250px;
}
.subText {
  color: rgba(255, 255, 255, 0.50);
   font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 8.8px */
  letter-spacing: -0.32px;
  width: 179px;
  position: absolute;
  bottom: 75px;
  left: 75px;
}

@media (min-width: 1920px) {
  //.blackBack {
  //  margin-top: -110px;
  //}
}

@media (max-width: 1920px)
{
  .titleTechnology {
    font-size: 20px;
  }
  .textTechnology {
    font-size: 15px;
    width: 140px;
  }
  .imageRombik {
    width: 40px;
  }

  .titleTechn {
    margin-bottom: 10px;
  }

  .technologyBorder:nth-child(1) .animationBorder {
    top: 99%;
    transform: rotate(180deg);
    left: 39%;
    width: 500px;
    z-index: -1;
  }
  .technologyBorder:nth-child(2) .animationBorder {
    top: 143%;
    transform: rotate(270deg);
    left: -39%;
    width: 500px;
    z-index: -1;
    display: none;
  }
  .technologyBorder:nth-child(3) .animationBorder {
    top: 99.5%;
    transform: rotate(180deg);
    left: 39%;
    width: 500px;
    z-index: -1;
  }
  .technologyBorder:nth-child(5) .animationBorder {
    top: 99%;
    transform: rotate(180deg);
    left: 39%;
    width: 500px;
    z-index: -1;

  }
  .technologyBorder:nth-child(6) .animationBorder {
    transform: rotate(-90deg) scaleY(1) translateY(37%) translateX(-103%);
    left: -25%;
    display: none;
    width: 400px;
    z-index: -1;
  }
  .technologyBorder:nth-child(7) .animationBorder {
    top: 99%;
    transform: rotate(180deg);
    left: 39%;
    width: 500px;
    z-index: -1;
  }
  .technologyBorder {
     width: 232.5px;
    height: 222px;


  }
  .technologyBorder:nth-child(1)  {
    grid-area: 1 / 2 / 2 / 3;
    transform: translateY(110px) translateX(15%) ;
  }
  .technologyBorder:nth-child(1) .titleTechnology {
    margin: 50px 0 0 25px;
  }
  .technologyBorder:nth-child(1) .imageRomb {
    position: absolute;
    right: 92.5%;
    top: -10px;
  }
  .technologyBorder:nth-child(1) .textTechnology {
    margin: 0 0 0 25px;
    position: absolute;
    top: 42%;
  }
  .technologyBorder:nth-child(2) {
    grid-area: 1 / 3 / 2 / 4;
    background-image: url("../../assets/technologyAssets/icon/KvadratBorder.svg");
    width: 229.5px;
    height: 221px;
    transform: translateY(20%);
  }
  .technologyBorder:nth-child(2) .imageRomb {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: -10px;
  }
  .technologyBorder:nth-child(2) .titleTechnology {
    text-align: center;
    margin: 60px auto 0 auto;
  }
  .technologyBorder:nth-child(2) .textTechnology {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 42%;
    text-align: center;
  }
  .technologyBorder:nth-child(3) {
    grid-area: 1 / 4 / 2 / 5;
    transform: scaleX(-1)  translateY(110px) translateX(15%);
    width: 233px;
    height: 223px;
  }
  .technologyBorder:nth-child(3) .titleTechnology {
    margin: 50px 0 0 35px;
    transform: scaleX(-1);
    text-align: end;
  }
  .technologyBorder:nth-child(3) .textTechnology {
    position: absolute;
    transform: scaleX(-1);
    top: 39.5%;
    text-align: end;
    margin: 0 0 0 35px;
  }
  .technologyBorder:nth-child(3) .imageRomb {
    position: absolute;
    right: 92.5%;
    top: -10px;
  }
  .technologyBorder:nth-child(4) {
    grid-area: 2 / 3 / 3 / 4;
    background-image: url("../../assets/technologyAssets/icon/image4.webp");
    width: 283px;
    height: 284px;
    flex-shrink: 0;
    margin: 100px 0 100px 0;
    z-index: 10;
  }
  .technologyBorder:nth-child(5) {
    grid-area: 3 / 2 / 4 / 3;
    transform: scaleY(-1) translateY(110px) translateX(15%);
    display: flex;
    width: 233px;
    height: 223px;
  }
  .technologyBorder:nth-child(5) .imageRomb {
    position: absolute;
    right: 92.5%;
    top: -10px;
  }
  .technologyBorder:nth-child(5) .titleTechnology {
    transform:  scaleY(-1);
    margin: 0 0 40px 26px;
  }
  .technologyBorder:nth-child(5) .textTechnology {
    transform:  scaleY(-1);
    position: absolute;
    margin: 0 0 0 26px;
    top: 25%;
  }
  .technologyBorder:nth-child(6) {
    grid-area: 3 / 3 / 4 / 4;
    background-image: url("../../assets/technologyAssets/icon/KvadratBorder.svg");
    width: 229.5px;
    height: 221px;
    transform:  scaleY(-1) translateY(20%);

  }
  .technologyBorder:nth-child(6) .titleTechnology {
    transform: translate(-50%) scaleY(-1);
    text-align: center;

    top: 80%;
    left: 50%;

  }
  .technologyBorder:nth-child(6) .textTechnology {
    transform:  scaleY(-1);
    text-align: center;
    margin: 0px  auto 0 auto;
    top: 35%;
    left: 21%;

  }
  .technologyBorder:nth-child(6) .imageRomb {
     left: 50%;
    transform: translate(-50%);
    top: -10px;
  }
  .technologyBorder:nth-child(7) {
    grid-area: 3 / 4 / 4 / 5;
    transform:  scaleX(-1) scaleY(-1) translateY(110px) translateX(15%);
    width: 233px;
    height: 223px;

  }
  .technologyBorder:nth-child(7) .titleTechnology {
    transform: scaleX(-1) scaleY(-1);
    margin: 0 0 40px 40px;
    text-align: end;

  }
  .technologyBorder:nth-child(7) .textTechnology {
    transform:  scaleY(-1) scaleX(-1);
    position: absolute;
    margin: 0 0 0 40px;
    top: 30%;
    text-align: end;
  }
  .technologyBorder:nth-child(7) .imageRomb {
    position: absolute;
    right: 92.5%;
    top: -10px;
  }
}

@media (max-width: 1536px) {
  .blackBack {
    margin-top: 150px;
    background-image: none;
  }

  .imageWorld {
     left: 50%;
  }
  .technologyGrid {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 400px);
    align-items: center;
    margin-top: 30px;
  }
  .technologyBorder {
    background-image: url("../../assets/technologyAssets/icon/MbKBorder.svg");
    background-size: cover;
    width: 369px;
    height: 330.5px;
  }
  .technologyBorder:nth-child(1) {
    grid-area: 2 / 3 / 3 / 4;
    transform: scaleX(-1) translateY(0%) translateX(-.5%);
    width: 369px;
    height: 330.5px;
  }
  .technologyBorder:nth-child(1) .titleTechnology {
    margin: 50px 0 0 50px;
    transform: scaleX(-1);
    text-align: end;
  }
  .technologyBorder:nth-child(1) .imageRomb {
    position: absolute;
    right: 92.5%;
    top: -1.5%;
  }
  .technologyBorder:nth-child(1) .textTechnology {
    position: absolute;
    transform: scaleX(-1);
    top: 36.5%;
    margin: 0 0 0 50px;
    text-align: end;
  }
  .technologyBorder:nth-child(2) {
    grid-area: 3 / 3 / 4 / 4;
    background-image: url("../../assets/technologyAssets/icon/BorderSeredina.svg");
    width: 379.5px;
    height: 325px;
    transform: scaleX(-1) translateY(-20%) translateX(-2%);
  }
  .technologyBorder:nth-child(2) .titleTechnology {
    margin: 30px 0px 0 65px;
    transform: scaleX(-1);
    text-align: end;
  }
  .technologyBorder:nth-child(2) .textTechnology {
    position: absolute;
    transform: scaleX(-1);
    top: 40.5%;
    text-align: end;
    left: 8%;
    margin: 0 0 0 34px;
  }
  .technologyBorder:nth-child(2) .imageRomb {
    left: 5%;
    top: 39.5%;
  }
  .technologyBorder:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
    transform: translateY(0%) translateX(1%);
    width: 369px;
    height: 330.5px;
  }
  .technologyBorder:nth-child(3) .titleTechnology {
    margin: 50px 0 0 47px;
    transform: scaleX(1);
    text-align: start;
  }
  .technologyBorder:nth-child(3) .textTechnology {
    position: absolute;
    transform: scaleX(1);
    margin: 0 0 0 47px;
    text-align: start;
    top: 37.5%;
  }
  .technologyBorder:nth-child(3) .imageRomb {
    position: absolute;
    right: 92.5%;
    top: -1.5%;
  }
  .technologyBorder:nth-child(4) {
    grid-area: 1 / 3 / 2 / 4;;
    width: 283px;
    height: 284px;
    position: absolute;
    transform: translateX(-65%);

  }
  .technologyBorder:nth-child(5) {
    grid-area: 3 / 2 / 4 / 3;
    background-image: url("../../assets/technologyAssets/icon/BorderSeredina.svg");
    background-size: cover;
    width: 379.5px;
    height: 325px;
    transform: scaleY(-1) translateY(20%) translateX(-1%);

  }
  .technologyBorder:nth-child(5) .titleTechnology {
    transform:  scaleY(-1);
    margin: 0 0 30px 56px;
  }
  .technologyBorder:nth-child(5) .textTechnology {
    transform:  scaleY(-1);
    position: absolute;
    margin: 0 0 30px 56px;
    top: 43%;
  }
  .technologyBorder:nth-child(5)::after {
    left: 5%;
    top: 99%;
    display: none;
  }
  .technologyBorder:nth-child(5) .imageRomb {
    position: absolute;
    top: 39.5%;
    left: -2%;
  }
  .technologyBorder:nth-child(6) {
    background-image: url("../../assets/technologyAssets/icon/MbKBorder.svg");
    background-size: cover;
    width: 369px;
    height: 330.5px;
    grid-area: 4 / 3 / 5 / 4;
    transform: translateY(-37.5%) translateX(0.5%) scaleX(-1) scaleY(-1);
  }
  .technologyBorder:nth-child(6) .titleTechnology {
    transform:  scaleX(-1) scaleY(-1);
    text-align: end;
    left: 0;
    margin-left: 50px;
  }
  .technologyBorder:nth-child(6) .textTechnology {
    transform:  scaleY(-1) scaleX(-1);
    text-align: end;
    margin: 55px  auto 0 50px;
    top: 40%;
    left: 0;
  }
  .technologyBorder:nth-child(6) .imageRomb {
    position: absolute;
    left: 0%;
  }
  .technologyBorder:nth-child(7) {
    width: 369px;
    height: 330.5px;
    grid-area: 4 / 2 / 5 / 3;
    transform: scaleY(-1) translateY(38%) translateX(1%);
  }
  .technologyBorder:nth-child(7) .titleTechnology {
    transform: scaleY(-1);
    margin: 0 0 40px 40px;
    text-align: start;
  }
  .technologyBorder:nth-child(7) .textTechnology {
    transform:  scaleY(-1) scaleX(1) translateX(2px);
    top: 57%;
    text-align: start;
  }
  .titleTechnology {
    font-size: 32px;
    width: 289px;
  }
  .textTechnology {
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    letter-spacing: -0.64px;
    width: 205px;
  }
  .animationBorder {
    display: none;
  }
  .technologyBorder:nth-child(1) .animationBorder {
    transform:   translateX(-31%) scaleX(-1) translateY(-130%);
  }
  .technologyBorder:nth-child(2) .animationBorder {
    display: none;
    top: 0;
    transform: rotate(0);
  }
  .technologyBorder:nth-child(3) .animationBorder {
    display: none;
  }
  .technologyBorder:nth-child(5) .animationBorder {
    transform: rotate(-180deg) translateX(29%) translateY(-118%);

  }
  .technologyBorder:nth-child(6) .animationBorder {
    display: none;
  }
  .technologyBorder:nth-child(7) .animationBorder {
    transform:  translateX(40%) rotate(180deg);
    left: 9%;
    width: 500px;
    z-index: -1;
    display: none;
  }
}

//@media (max-width: 1200px) {
//  .technologyBorder {
//    width: 280.5px;
//    height: 268px;
//  }
//  .technologyBorder:nth-child(1) {
//    grid-area: 1 / 2 / 2 / 3;
//    transform: translateY(40%) translateX(53%);
//
//  }
//  .technologyBorder:nth-child(2) {
//    grid-area: 1 / 3 / 2 / 4;
//    width: 278.5px;
//    height: 265px;
//  }
//  .technologyBorder:nth-child(3) {
//    grid-area: 1 / 4 / 2 / 5;
//    transform:   scaleX(-1) translateY(40%) translateX(53%);
//
//  }
//  .technologyBorder:nth-child(5) {
//    grid-area: 3 / 2 / 4 / 3;
//    transform: scaleY(-1) translateY(40%) translateX(53%);
//  }
//  .technologyBorder:nth-child(6) {
//
//    width: 278.5px;
//    height: 265px;
//  }
//  .technologyBorder:nth-child(7) {
//    grid-area: 3 / 4 / 4 / 5;
//    transform:  scaleX(-1) scaleY(-1) translateY(40%) translateX(53%);
//  }
//  .technologyBorder:nth-child(1)::after {
//    left: 5%;
//  }
//  .technologyBorder:nth-child(3)::after {
//    left: 5%;
//  }
//  .technologyBorder:nth-child(5)::after {
//    left: 5%;
//    top: 99%;
//  }
//
//  .titleTechnology {
//    font-size: 21px;
//    width: 203px;
//  }
//  .textTechnology {
//    color: #FFF;
//    font-size: 15px;
//    font-style: normal;
//    font-weight: 400;
//    line-height: 110%; /* 17.6px */
//    letter-spacing: -0.64px;
//    width: 205px;
//  }
//}

@media (max-width: 1024px) {
  // .technologyBorder {
  //  background-image: url("../../assets/technologyAssets/icon/MbKBorder.svg");
  //  background-size: cover;
  //  width: 369px;
  //  height: 330.5px;
  //}
  //.technologyBorder:nth-child(1) {
  //  grid-area: 2 / 3 / 3 / 4;
  //  transform: scaleX(-1) translateY(0%) translateX(-.5%);
  //  width: 369px;
  //  height: 330.5px;
  //}
  //.technologyBorder:nth-child(1) .titleTechnology {
  //  margin: 50px 0 0 27px;
  //  transform: scaleX(-1);
  //  text-align: end;
  //}
  //.technologyBorder:nth-child(1) .imageRomb {
  //  position: absolute;
  //  right: 92.5%;
  //  top: -1.5%;
  //}
  //.technologyBorder:nth-child(1) .textTechnology {
  //  position: absolute;
  //  transform: scaleX(-1);
  //  top: 80.5%;
  //  margin: 0 0 0 27px;
  //  text-align: end;
  //}
  //.technologyBorder:nth-child(2) {
  //  grid-area: 3 / 3 / 4 / 4;
  //  background-image: url("../../assets/technologyAssets/icon/BorderSeredina.svg");
  //  width: 379.5px;
  //  height: 325px;
  //  transform: scaleX(-1) translateY(-20%) translateX(-2%);
  //}
  //.technologyBorder:nth-child(2) .titleTechnology {
  //  margin: 30px 0px 0 45px;
  //  transform: scaleX(-1);
  //  text-align: end;
  //}
  //.technologyBorder:nth-child(2) .textTechnology {
  //  position: absolute;
  //  transform: scaleX(-1);
  //  top: 75.5%;
  //  text-align: end;
  //  left: 8%;
  //  margin: 0 0 0 10px;
  // }
  //.technologyBorder:nth-child(2) .imageRomb {
  //  left: 5%;
  //  top: 39.5%;
  //}
  // .technologyBorder:nth-child(3) {
  //  grid-area: 2 / 2 / 3 / 3;
  //  transform: translateY(0%) translateX(1%);
  //}
  //.technologyBorder:nth-child(3) .titleTechnology {
  //  margin: 50px 0 0 26px;
  //  transform: scaleX(1);
  //  text-align: start;
  //}
  //.technologyBorder:nth-child(3) .textTechnology {
  //  position: absolute;
  //  transform: scaleX(1);
  //   margin: 0 0 0 26px;
  //}
  //.technologyBorder:nth-child(3) .imageRomb {
  //  position: absolute;
  //  right: 92.5%;
  //  top: -1.5%;
  //}
  //.technologyBorder:nth-child(4) {
  //  grid-area: 1 / 3 / 2 / 4;;
  //  width: 283px;
  //  height: 284px;
  //  position: absolute;
  //  left: 15%;
  //}
  //.technologyBorder:nth-child(5) {
  //  grid-area: 3 / 2 / 4 / 3;
  //  background-image: url("../../assets/technologyAssets/icon/BorderSeredina.svg");
  //  background-size: cover;
  //  width: 379.5px;
  //  height: 325px;
  //  transform: scaleY(-1) translateY(20%) translateX(-1%);
  //
  //}
  //.technologyBorder:nth-child(5) .titleTechnology {
  //  transform:  scaleY(-1);
  //  margin: 0 0 30px 43px;
  //}
  //.technologyBorder:nth-child(5) .textTechnology {
  //  transform:  scaleY(-1);
  //  position: absolute;
  //  margin: 0 0 30px 44px;
  //  top: 8%;
  //}
  //.technologyBorder:nth-child(5)::after {
  //  left: 5%;
  //  top: 99%;
  //  display: none;
  //}
  //.technologyBorder:nth-child(5) .imageRomb {
  //  position: absolute;
  //  top: 39.5%;
  //  left: -2%;
  //}
  // .technologyBorder:nth-child(6) {
  //  background-image: url("../../assets/technologyAssets/icon/MbKBorder.svg");
  //  background-size: cover;
  //  width: 369px;
  //  height: 330.5px;
  //  grid-area: 4 / 3 / 5 / 4;
  //  transform: translateY(-37.5%) translateX(0.5%) scaleX(-1) scaleY(-1);
  //}
  //.technologyBorder:nth-child(6) .titleTechnology {
  //  transform:  scaleX(-1) scaleY(-1);
  //  text-align: end;
  //  left: 0;
  //  margin-left: 24px;
  //}
  //.technologyBorder:nth-child(6) .textTechnology {
  //  transform:  scaleY(-1) scaleX(-1);
  //  text-align: end;
  //  margin: 55px  auto 0 24px;
  //}
  //.technologyBorder:nth-child(6) .imageRomb {
  //  position: absolute;
  //  left: 0%;
  // }
  //.technologyBorder:nth-child(7) {
  //  width: 369px;
  //  height: 330.5px;
  //  grid-area: 4 / 2 / 5 / 3;
  //  transform: scaleY(-1) translateY(38%) translateX(1%);
  //}
  //.technologyBorder:nth-child(7) .titleTechnology {
  //  transform: scaleY(-1);
  //  margin: 0 0 40px 26px;
  //  text-align: start;
  //}
  //.technologyBorder:nth-child(7) .textTechnology {
  //  transform:  scaleY(-1) scaleX(1);
  //}
  // .titleTechnology {
  //  font-size: 32px;
  //  width: 289px;
  // }
  //.textTechnology {
  //  color: #FFF;
  //  font-size: 15px;
  //  font-style: normal;
  //  font-weight: 400;
  //  line-height: 110%; /* 17.6px */
  //  letter-spacing: -0.64px;
  //  width: 205px;
  //}
  //.animationBorder {
  //  display: none;
  //}
  //.technologyBorder:nth-child(1) .animationBorder {
  //   transform:   translateX(-31%) scaleX(-1) translateY(-130%);
  //}
  //.technologyBorder:nth-child(2) .animationBorder {
  //  display: none;
  //  top: 0;
  //  transform: rotate(0);
  // }
  //.technologyBorder:nth-child(3) .animationBorder {
  //  display: none;
  //}
  //.technologyBorder:nth-child(5) .animationBorder {
  //   transform: rotate(-180deg) translateX(29%) translateY(-118%);
  //
  //}
  //.technologyBorder:nth-child(6) .animationBorder {
  //
  //  display: none;
  //}
  //.technologyBorder:nth-child(7) .animationBorder {
  //  transform:  translateX(40%) rotate(180deg);
  //  left: 9%;
  //  width: 500px;
  //  z-index: -1;
  //  display: none;
  // }
}

@media (max-width: 834px)  {
  .blackBack {
    margin-top: 0;
  }
  .imageWorld {
    left: 49.5%;
    }
  .technologyGrid {
    grid-template-columns: repeat(1, 580px);
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    justify-content: center;
    margin-top: 150px;
    gap: 10px;
  }
  .technologyBorder {
    background-image:url("../../assets/technologyAssets/icon/MB400.webp");
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 549px;
    width: 100%;
    height: 306px;
    grid-area: auto;
    }
  .technologyBorder:nth-child(1) {
    transform: scaleX(1)  translateY(0%) translateX(0%);
    max-width: 549px;
    width: 100%;
    height: 306px;
    grid-area: auto;
    grid-row: 4 ;

   }
  .technologyBorder:nth-child(1) .titleTechnology {
    margin: 0px 0px 0 30px;
    transform: scaleX(1);
    position: absolute;
    top: 32.5%;
    text-align: start;
  }
  .technologyBorder:nth-child(1) .textTechnology {
    position: absolute;
    transform: scaleX(1);
     top: 57.5%;
    text-align: start;
    left: 5%;
    margin: 0 0 0 4px;
  }
  .technologyBorder:nth-child(1) .imageRomb {
    left: 4%;
    top: 9.5%;
  }
  .technologyBorder:nth-child(2) {
    background-image:url("../../assets/technologyAssets/icon/MB400.webp");
    transform: translateY(0%) translateX(0%) scaleX(1);
    max-width: 549px;
    width: 100%;
    height: 306px;
    grid-area: auto;
    grid-row: 2 ;

  }
  .technologyBorder:nth-child(2) .titleTechnology {
    margin: 0px 0px 0 30px;
    transform: scaleX(1);
    text-align: start;
    position: absolute;
    top: 33.5%;
  }
  .technologyBorder:nth-child(2) .textTechnology {
    position: absolute;
    transform: scaleX(1);
    top: 58.5%;
    text-align: start;
    left: 5%;
    margin: 0 0 0 5px;
  }
  .technologyBorder:nth-child(2) .imageRomb {
    left: 8.5%;
    top: 9.5%;
  }
  .technologyBorder:nth-child(3) {
    transform: translateY(0%) translateX(0%);
    max-width: 549px;
    width: 100%;
    height: 306px;
    grid-area: auto;
    grid-row: 3 ;
  }
  .technologyBorder:nth-child(3) .titleTechnology {
    margin: 0px 0px 0 32px;
    transform: scaleX(1);
    text-align: start;
    position: absolute;
    top: 33.5%;
  }
  .technologyBorder:nth-child(3) .textTechnology {
    position: absolute;
    transform: scaleX(1);
    top: 52.5%;
    text-align: start;
    left: 3%;
    margin: 0 0 0 14px;
  }
  .technologyBorder:nth-child(3) .imageRomb {
    left: 4%;
    top: 9.5%;
  }
  .technologyBorder:nth-child(4) {
    grid-area: 1 / 3 / 2 / 4;;
    width: 283px;
    height: 284px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-30%);

  }
  .technologyBorder:nth-child(5) {
    background-image:url("../../assets/technologyAssets/icon/MB400.webp");
    max-width: 549px;
    width: 100%;
    height: 306px;
    transform:   translateY(0%) translateX(0%);
    grid-area: auto;
    grid-row: 5 ;
  }
  .technologyBorder:nth-child(5) .titleTechnology {
    margin: 0 0px 0 31px;
    position: absolute;
    top: 31.5%;
    transform: scaleX(1);
    text-align: start;
  }
  .technologyBorder:nth-child(5) .textTechnology {
    position: absolute;
    transform: scaleX(1);
    top: 65.5%;
    text-align: start;
    left: 2%;
    margin: 0 0 0 20px;
  }
  .technologyBorder:nth-child(5) .imageRomb {
    left: 4%;
    top: 9.5%;
  }
  .technologyBorder:nth-child(6) {
    background-image:url("../../assets/technologyAssets/icon/MB400.webp");
    max-width: 549px;
    width: 100%;
    height: 306px;
     background-repeat: no-repeat;
    background-size: cover;
    transform: translateY(0) translateX(0)  scaleY(-1) ;
    grid-area: auto;
    grid-row: 6 ;
  }
  .technologyBorder:nth-child(6) .titleTechnology {
    margin: 0px 0px 0 30px;
    transform: scaleX(1) scaleY(-1) ;
    text-align: start;
    position: absolute;
    top: 56.5%;
  }
  .technologyBorder:nth-child(6) .textTechnology {
    transform: scaleX(1) scaleY(-1);
    margin: 0 0 0 30px;
    text-align: start;
    position: absolute;
    bottom: 48.5%;

  }
  .technologyBorder:nth-child(6) .imageRomb {
    left: 8%;
    top: 75%;
  }
  .technologyBorder:nth-child(7) {
    transform:  translateY(0) translateX(0%);
    grid-area: auto;
    grid-row: 7 ;
    max-width: 549px;
    width: 100%;
    height: 306px;
  }
  .technologyBorder:nth-child(7) .titleTechnology {
    margin: 0 0px 0 30px;
    transform: scaleX(1) scaleY(1) ;
    position: absolute;
    top: 31.5%;
    text-align: start;
  }
  .technologyBorder:nth-child(7) .textTechnology {
    transform: scaleX(1) scaleY(1);
    margin: 0 0 0 30px;
    text-align: start;
    position: absolute;
    top: 48.5%;
  }
  .technologyBorder:nth-child(7) .imageRomb {
    left: 3.5%;
    top: 9.5%;
  }
  .titleTechnology {
    font-size: 32px;
    width: 289px;
  }

}

@media (max-width: 570px)  {
  .titleTechn {
    color: var(--White, #FFF);
    text-align: center;
    font-family: Goldman;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 90%; /* 52.2px */
    letter-spacing: -2.32px;
    text-transform: uppercase;
    margin-top: 60px;
    margin-bottom: 85px;
  }

  .titleTechnology {
     font-size: 24px !important;
  }

  .technologyGrid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin-top: 200px;
    gap: 10px;
  }
  .technologyBorder {
     background-size: cover;background-repeat: no-repeat;
    max-width: 350px;
    width: 100%;
    height: 194px;
    grid-area: auto;
  }
  .technologyBorder:nth-child(1) {
    transform: scaleX(1)  translateY(0%) translateX(0%);
    max-width: 400px;
    width: 100%;
    height: 223px;
    grid-area: auto;
    grid-row: 4 ;

  }
  .technologyBorder:nth-child(1) .titleTechnology {
    margin: 0px 0px 0 17px;
    transform: scaleX(1);
    position: absolute;
    top: 34.5%;
    text-align: start;
  }
  .technologyBorder:nth-child(1) .textTechnology {
    position: absolute;
    transform: scaleX(1);
     top: 50.5%;
    text-align: start;
    left: 3%;
    margin: 0 0 0 5px;
    font-size: 16px;
  }
  .technologyBorder:nth-child(1) .imageRomb {
    left: 4%;
    top: 9.5%;
  }
  .technologyBorder:nth-child(2) {
     transform: translateY(0%) translateX(0%) scaleX(1);
    max-width: 400px;
    width: 100%;
    height: 223px;
    grid-area: auto;
    grid-row: 2 ;

  }
  .technologyBorder:nth-child(2) .titleTechnology {
    margin: 0px 0px 0 17px;
    transform: scaleX(1);
    text-align: start;
    position: absolute;
    top: 36.5%;
  }
  .technologyBorder:nth-child(2) .textTechnology {
    position: absolute;
    transform: scaleX(1);
    top: 52.5%;
    text-align: start;
    left: 3%;
    margin: 0 0 0 5px;
  }
  .technologyBorder:nth-child(2) .imageRomb {
    left: 10%;
    top: 9.5%;
  }
  .technologyBorder:nth-child(3) {
    transform: translateY(0%) translateX(0%);
    max-width: 400px;
    width: 100%;
    height: 223px;
    grid-area: auto;
    grid-row: 3 ;
  }
  .technologyBorder:nth-child(3) .titleTechnology {
    margin: 0px 0px 0 19px;
    transform: scaleX(1);
    text-align: start;
    position: absolute;
    top: 36.5%;

  }
  .technologyBorder:nth-child(3) .textTechnology {
    position: absolute;
    transform: scaleX(1);
    top: 53.5%;
    text-align: start;
    left: 3%;
    margin: 0 0 0 5px;
  }
  .technologyBorder:nth-child(3) .imageRomb {
    left: 4%;
    top: 9.5%;
  }

  .technologyBorder:nth-child(5) {
     max-width: 400px;
    width: 100%;
    height: 223px;
    transform:   translateY(0%) translateX(0%);
    grid-area: auto;
    grid-row: 5 ;
  }
  .technologyBorder:nth-child(5) .titleTechnology {
    margin: 0 0 0 19px;
    position: absolute;
    top: 35.5%;
    transform: scaleX(1);
    text-align: start;
  }
  .technologyBorder:nth-child(5) .textTechnology {
    position: absolute;
    transform: scaleX(1);
    top: 60.5%;
    text-align: start;
    left: 2%;
    margin: 0 0 0 11px;
  }
  .technologyBorder:nth-child(5) .imageRomb {
    left: 4%;
    top: 9.5%;
  }
  .technologyBorder:nth-child(6) {
     max-width: 400px;
    width: 100%;
    height: 223px;
    transform: translateY(0) translateX(0)  scaleY(-1) ;
    grid-area: auto;
    grid-row: 6 ;
  }
  .technologyBorder:nth-child(6) .titleTechnology {
    margin: 0px 0px 0 16px;
    transform: scaleX(1) scaleY(-1) ;
    text-align: start;
    position: absolute;
    top: 58.5%;
  }
  .technologyBorder:nth-child(6) .textTechnology {
    transform: scaleX(1) scaleY(-1);
    margin: 0 0 0 16px;
    text-align: start;
    position: absolute;
    bottom: 48.5%;

  }
  .technologyBorder:nth-child(6) .imageRomb {
    left: 10%;
    bottom: 10.5%;
  }
  .technologyBorder:nth-child(7) {
    transform:  translateY(0) translateX(0%);
    grid-area: auto;
    grid-row: 7 ;
    max-width: 400px;
    width: 100%;
    height: 223px;
  }
  .technologyBorder:nth-child(7) .titleTechnology {
    margin: 0 0px 0 18px;
    transform: scaleX(1) scaleY(1) ;
    position: absolute;
    top: 34.5%;
    text-align: start;
  }
  .technologyBorder:nth-child(7) .textTechnology {
    transform: scaleX(1) scaleY(1);
    text-align: start;
    margin: 0 0px 0 15px;
    position: absolute;
    top: 51.5%;

  }
  .technologyBorder:nth-child(7) .imageRomb {
    left: 3.5%;
    top: 9.5%;
  }
  .titleTechnology {
    font-size: 32px;
    width: 289px;
  }

}



@media (max-width: 400px) {
  .technologyBorder {
   }
  .technologyBorder:nth-child(1) {
    max-width: 330px;
    width: 100%;
    height: 184px;
  }
  .technologyBorder:nth-child(2) {
    max-width: 330px;
    width: 100%;
    height: 184px;
  }
  .technologyBorder:nth-child(3) {
    max-width: 330px;
    width: 100%;
    height: 184px;
  }
  .technologyBorder:nth-child(1) {}
  .technologyBorder:nth-child(5) {
    max-width: 330px;
    width: 100%;
    height: 184px;
  }
  .technologyBorder:nth-child(6) {
    max-width: 330px;
    width: 100%;
    height: 184px;
  }
  .technologyBorder:nth-child(7) {
    max-width: 330px;
    width: 100%;
    height: 184px;
  }

    .technologyBorder:nth-child(1) .titleTechnology {
      margin: 7px 0px 0 16px;
       text-align: start;
       position: absolute;

     }
  .technologyBorder:nth-child(1) .textTechnology {
     margin: 10px 0 0 8px;
    text-align: start;
    position: absolute;
    width: 300px;

  } .technologyBorder:nth-child(2) .titleTechnology {
      margin: 0px 0px 0 16px;
       text-align: start;
      position: absolute;
     }
  .technologyBorder:nth-child(2) .textTechnology {
    margin: 0 0 0 8px;
    text-align: start;
    position: absolute;
    width: 300px;

  } .technologyBorder:nth-child(3) .titleTechnology {
      margin: 0px 0px 0 19px;
      text-align: start;
      top: 37%;
     }
  .technologyBorder:nth-child(3) .textTechnology {
     margin: 0 0 0 9px;
    text-align: start;
    position: absolute;
    bottom: 72.5%;

  } .technologyBorder:nth-child(5) .titleTechnology {
      margin: 0px 0px 0 15px;
      text-align: start;
      top:38% ;

     }
  .technologyBorder:nth-child(5) .textTechnology {
    margin: 10px 0 0 10px;
    text-align: start;

  } .technologyBorder:nth-child(6) .titleTechnology {
      margin: 0px 0px 0 16px;
        top: 50%;
     }
  .technologyBorder:nth-child(6) .textTechnology {
    margin: 0 0 10px 16px;
  }
  .technologyBorder:nth-child(6) .imageRomb {
    margin: 0 0 0 16px;
    top: 65%;
    left: 6%;
  }

  .technologyBorder:nth-child(7) .titleTechnology {
    margin: 0px 0px 0 19px;
    top: 37%;
  }
  .technologyBorder:nth-child(7) .textTechnology {
    margin: 0 0 0 19px;
    top: 53%;
  }


}