.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.titleModal {
  color: var(--White, #FFF);
  font-family: Goldman;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 52.2px */
  letter-spacing: -2.32px;
  text-transform: uppercase;
}

.modalSubtitle {
  color: var(--White, #FFF);
  margin-top: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 20.8px */
  letter-spacing: -0.16px;
}

.modalContainer {
  background-color: #DF450F; /* Оранжевый цвет, как на вашем изображении */
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 890px;
  height: 720px;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  margin: 0 20px;
}
.closeButton {
  width: 66px;
  height: 66px;
  flex-shrink: 0;
  background: rgba(247, 247, 247, 0.10);
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #FFFFFF;
}

.modalForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 77px;
}

.modalInput, .modalTextarea {
  padding: 10px;
  font-size: 16px;
  border-radius: 16px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: rgba(247, 247, 247, 0.10);
  &::placeholder {
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}


.modalTextarea {
  min-height: 151px;
   resize: none;
}
.modalSubmit {
  padding: 29px;
  font-size: 16px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  text-align: center;
}

.modalSubmit:hover {
  color: #DF450F;
  transition: .3s;
}

.modalSubtitleBotton {
  text-align: center;
  margin: 40px 0 17px 0 ;
  color: white;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: #000000;
  border-radius: 50%;
  position: absolute;
  z-index: 2;

  &.top-left {
    top: 10px;
    left: 10px;
  }
  &.top-right {
    top: 10px;
    right: 10px;
  }
  &.bottom-left {
    bottom: 10px;
    left: 10px;
  }
  &.bottom-right {
    bottom: 10px;
    right: 10px;
  }
}

@media (max-width: 1536px) {
  .modalContainer {
    width: 80%;
    height: 666px;
  }
  .modalTextarea {
    min-height: 151px;
    padding-top: 15px;
    resize: none;
  }
  .modalSubtitleBotton {
    margin: 20px 0 10px 0 ;
  }
  .modalForm {
    margin-top: 55px;
  }
  .titleModal {
    font-size: 50px;
  }
}


@media (max-width: 834px) {
  .modalContainer {
    width: 80%;
    height: 606px;
   }
  .modalTextarea {
    min-height: 121px;
    padding-top: 15px;
    resize: none;
  }
  .modalSubtitleBotton {
    margin: 20px 0 10px 0 ;
  }
  .modalForm {
    margin-top: 55px;
  }

  .modalSubmit {
    padding: 20px;
    font-size: 16px;
  }

  .modalInput, .modalTextarea {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .modalContainer {
    height: 636px;
  }
  .modalSubtitleBotton {
    margin: 8px 0 8px 0 ;
  }
  .modalContainer {
    padding-bottom: 100px;
  }
  .closeButton {
    right: 0;
    left: 20px;
  }
  .titleModal {
    margin-top: 70px;
    font-size: 40px;
  }
  .modalForm {
    margin-top: 30px;
  }
  .modalSubtitleBotton {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .modalSubtitle {
    font-size: 12px;
    margin-top: 7px;
  }
  .modalContainer {
    height: 600px;
  }
  .modalSubtitleBotton {
    margin: 8px 0 8px 0 ;
  }
  .modalContainer {
    padding-bottom: 100px;
  }
  .closeButton {
    right: 0;
    left: 20px;
  }
  .titleModal {
    margin-top: 70px;
    font-size: 30px;
  }
  .modalForm {
    margin-top: 30px;
  }
  .modalSubtitleBotton {
    font-size: 12px;
  }
  .modalSubmit {
    padding: 15px;
  }
}

@media (max-width: 350px) {
  .modalContainer {
    height: 615px;
  }
}