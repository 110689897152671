.containerAudienceProfiles {
  min-height: calc(100vh - 97px);
  margin-top: 100px;
 }

.main {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 100px 0 100px 0;
  }
.wrapperIconsBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0 20px 80px 20px;
 }
.titleAudience {
  color: var(--White, #FFF);
  text-align: center;
  font-family: Goldman;
  font-size: 58px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 52.2px */
  letter-spacing: -1px;
  text-transform: uppercase;

}

.containerIcons {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);

  border-radius: 50%;
  width: 930px;
  height: 930px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid  rgba(255, 255, 255, 0.1);
}
.containerImageBlock {

  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
}

.containerIcons::before,
.containerIcons::after {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.containerIcons::before {
  max-width: 680px;
  height: 680px;
  width: 100%;
  border: 2px dashed  rgba(255, 255, 255, 0.1); /* Adjust color if needed */
}
.subTitleVerticalSoty-6 {
 }
.containerImageBlock-0 {
  transform: translateY(25.5%) translateX(-108%);
  top: 0;
  left: 0;
}
.containerImageBlock-1 {
  transform: translateY(25.5%) translateX(29.5%);
  top: 0;
}
.containerImageBlock-2 {
  transform: translateY(62%) translateX(-29%);
  left: 0;
}
.containerImageBlock-4 {
  transform: translateY(-128%) translateX(-51%);
  top: 0;
}
.containerImageBlock-5 {
  transform:translateY(-75%) translateX(32%);
  top: 0;
}
.containerImageBlock-6 {
  transform: translateY(-79%) translateX(-105%);
  left: 0;
  top: 0;
}

.subTitleVerticalSoty {
  background: rgba(0, 0, 0, 1);
  border-radius: 20px ;
  width: 450px;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: white;
  backdrop-filter: blur(55px);
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.24px;
  position: absolute;
  bottom: -100px;
  z-index: 2;
 }

.containerIcons::after {
  max-width: 700px;
  height: 700px;
  width: 100%;
  border: 2px dotted  rgba(255, 255, 255, 0.1);
}
.flex_row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
  width: 400px;
  height: 400px;
  position: relative;
  justify-items: center;
 }
.imageProfiles {
  height:206px;
  max-width:230px  ;
  border-radius: 20px;
}
.idWordData {
  color: #FFF;
   font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.18px;
  margin-left: 5px;
  margin-top: 20px;
}

.thumbnail {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
  cursor: pointer;
}
.thumbnail div.clicked svg {
  fill: #DF450F;
}
.thumbnail .svg-icon {
  color: white;
}

.icon-1 {
  grid-area: 1 / 1;
  transform: translateY(10.5%) translateX(-86%);
  z-index: 1;
}
.icon-2 {
  grid-area: 3 / 2;
  transform: translateY(118%) translateX(1%);
  z-index: 1;
}
.icon-3 {
  grid-area: 3 / 1;
  transform: translateY(-10%) translateX(-85%);
  z-index: 1;
}
.icon-4 {
  grid-area: 2 / 2;
  z-index: -1;
  box-shadow: 0px 0px 105px 75px white;
  border-radius: 150px;
}
.icon-5 {
  grid-area: 1 / 3;
  z-index: 1;
  transform: translateY(9%) translateX(86%);
}
.icon-6 {
  grid-area: 2 / 3;
  z-index: 1;
  transform:translateY(129%) translateX(87%);
}
.icon-7 {
  grid-area: 1 / 2;
  z-index: 1;
  transform:translateY(-118%) translateX(-3%)
}
.center-icon {
  grid-area: 2 / 2;
  width: 120px;
  height: 120px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.activeText {
  font-size: 20px;
  text-align: center;
}
.activeTitle {
  font-size: 24px;
  margin-bottom: 12px;
  font-family: Goldman;
   font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.24px;
  text-align: center;
}

.subTitleVerticalSoty-0 {
  right: 15%;
  bottom: -33%;
}
.subTitleVerticalSoty-1 {
  left: 83%;
  bottom: 74%;
}

.subTitleVerticalSoty-2 {
  right: 96%;
  bottom: 82%;
}

.subTitleVerticalSoty-6 {
  right: 91%;
  bottom: 0%;
}

.subTitleVerticalSoty-4 {
  left: 100%;
  bottom: -18%;
}

.subTitleVerticalSoty-5 {
  left: 15%;
  bottom: -60%;
}

.imageProfiles-1 {
  transform:translateY(-2%) translateX(-7.5%);
}
.imageProfiles-2 {
  transform:translateY(-10%) translateX(-4.5%);
}
.imageProfiles-3 {
  transform:translateY(-7.5%) translateX(-2.5%);
}
.imageProfiles-4 {
  transform:translateY(10.5%) translateX(2.5%);
}

.imageProfiles-5 {
  transform:translateY(10%) translateX(-5%);
}

.imageProfiles-6 {
  transform:translateY(1%) translateX(8%);
}


@media (min-width: 1921px) {


  .containerIcons::before {
    max-width: 910px;
    height: 910px;
    width: 100%;
    border: 2px dashed  rgba(255, 255, 255, 0.1); /* Adjust color if needed */
  }
  .containerIcons::after {
    max-width: 920px;
    height: 920px;
    width: 100%;
    border: 2px dotted  rgba(255, 255, 255, 0.1); /* Adjust color if needed */
  }


.imageProfiles {
  width: 300px;
  max-width: 100%;
  height: 280px;
}
  .imageProfiles-1 ,.imageProfiles-2 ,.imageProfiles-3 ,.imageProfiles-4 ,.imageProfiles-5 ,.imageProfiles-6 {
    width: 300px;
     height: 260px;
  }
  .imageProfiles-0 {
    transform:translateY(-6%) translateX(6%);
    width: 300px;
    height: 260px;
  }


  .containerImageBlock {
    height: 300px;
    width: 300px;
  }
  .containerImageBlock-0 {
    transform: translateY(22.5%) translateX(-118%);
    top: 0;
    left: 0;
  }
  .containerImageBlock-1 {
    transform:translateY(19%) translateX(36.5%);;
    top: 0;
  }
  .containerImageBlock-2 {
    transform: translateY(60%) translateX(-30%);
    left: 0;
  }
  .containerImageBlock-4 {
    transform: translateY(-124%) translateX(-54%);
    top: 0;
  }
  .containerImageBlock-5 {
    transform:translateY(-78%) translateX(33%);
    top: 0;
  }
  .containerImageBlock-6 {
    transform: translateY(-73%) translateX(-118%);
    left: 0;
    top: 0;
  }

}

@media (max-width: 1920px) {
  .main {
    margin-top: 70px;
  }
  .containerIcons::before {
    max-width: 710px;
    height: 710px;
    width: 100%;
    border: 2px dashed  rgba(255, 255, 255, 0.1); /* Adjust color if needed */
  }
  .containerIcons::after {
    max-width: 720px;
    height: 720px;
    width: 100%;
    border: 2px dotted  rgba(255, 255, 255, 0.1); /* Adjust color if needed */
  }
  .containerIcons {
     border-radius: 50%;
    width: 730px;
    height: 730px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid  rgba(255, 255, 255, 0.1);
  }
  .subTitleVerticalSoty-0 {
    right: 17%;
    bottom: -92%;
  }
  .subTitleVerticalSoty-1 {
    left: 97%;
    bottom: 50%;
  }

  .subTitleVerticalSoty-2 {
    right: 90%;
    bottom: 51%;
  }

  .subTitleVerticalSoty-6 {
    right: 98%;
    bottom: -50%
  }

  .subTitleVerticalSoty-4 {
    left: 99%;
    bottom: -56%;
  }

  .subTitleVerticalSoty-5 {
    left: 18%;
    bottom: -114%;
  }
  .icon-1 {
    grid-area: 1 / 1;
    transform: translateY(49.5%) translateX(-20%);
    z-index: 1;
  }
  .icon-2 {
    grid-area: 3 / 2;
    transform: translateY(41%) translateX(-6%);
    z-index: 1;
  }
  .icon-3 {
    grid-area: 3 / 1;
    transform: translateY(-50%) translateX(-22%);
    z-index: 1;
  }
  .icon-4 {
    grid-area: 2 / 2;
    z-index: -1;
    box-shadow: 0px 0px 105px 75px white;
    border-radius: 150px;
  }
  .icon-5 {
    grid-area: 1 / 3;
    z-index: 1;
    transform: translateY(50%) translateX(18%);
  }
  .icon-6 {
    grid-area: 2 / 3;
    z-index: 1;
    transform:translateY(94%) translateX(15%);
  }
  .icon-7 {
    grid-area: 1 / 2;
    z-index: 1;
    transform:translateY(-40%) translateX(-2%)
  }
.imageProfiles {
  width: 190px;
  height: 171px;
}
  .imageProfiles-0 {
    transform:translateY(6%) translateX(1.5%);
  }
  .imageProfiles-1 {
    transform:translateY(8%) translateX(3.5%);
   }
  .imageProfiles-2 {
    transform:translateY(5%) translateX(5.5%);

  }
  .imageProfiles-3 {
    transform:translateY(-7.5%) translateX(-2.5%);
  }
  .imageProfiles-4 {
    transform:translateY(1.5%) translateX(-1.5%);
  }

  .imageProfiles-5 {
    transform:translateY(3%) translateX(1%);
  }

  .imageProfiles-6 {
    transform:translateY(5%) translateX(-1%);
  }
}

@media (max-width: 1536px) {
  .main {
    margin-top: 55px;
  }
  .icon-4 {
    grid-area: 2 / 2;
    z-index: -1;
    box-shadow: 0px 0px 50px 15px white;
    border-radius: 150px;
  }
  .subTitleVerticalSoty {
    background: rgba(0, 0, 0, 1);
    font-size: 20px;
    border-radius: 20px ;
    width: 400px;
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: white;
    backdrop-filter: blur(15px);
    position: absolute;
    bottom: -100px;
  }
  .containerIcons {
     border-radius: 50%;
    width: 520px ;
    height: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid  rgba(255, 255, 255, 0.1);
  }
  .imageProfiles {
    width: 160px;
    height: 130px;
  }
  .imageProfiles-0 {
    transform:translateY(11%) translateX(6%);
  }
  .imageProfiles-1 {
    transform:translateY(7%) translateX(4.5%);
  }
  .imageProfiles-2 {
    transform:translateY(7%) translateX(3.5%);
  }
  .imageProfiles-3 {
    transform:translateY(-7.5%) translateX(-2.5%);
  }
  .imageProfiles-4 {
    transform:translateY(6.5%) translateX(2.5%);
  }
  .imageProfiles-5 {
    transform:translateY(6%) translateX(-1%);
  }
  .imageProfiles-6 {
    transform:translateY(12%) translateX(2%);
  }

  .containerIcons::before,
  .containerIcons::after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }
  .containerIcons::before {
    max-width: 510px;
    height: 510px;
    width: 100%;
    border: 2px dashed  rgba(255, 255, 255, 0.1); /* Adjust color if needed */
  }
  .containerIcons::after {
    max-width: 500px;
    height: 500px;
    width: 100%;
    border: 2px dotted  rgba(255, 255, 255, 0.1); /* Adjust color if needed */
  }
  .icon-1 {
    grid-area: 1 / 1;
    transform:translateY(67.5%) translateX(12%);
    z-index: 1;
  }

  .icon-2 {
    grid-area: 3 / 2;
    transform: translateY(7%) translateX(-2%);
    z-index: 1;
  }

  .icon-3 {
    grid-area: 3 / 1;
    transform: translateY(-67.5%) translateX(12%);
    z-index: 1;
  }

  .icon-4 {
    grid-area: 2 / 2;
    z-index: -1;

  }

  .icon-5 {
    grid-area: 1 / 3;
    z-index: 1;
    transform: translateY(67%) translateX(-13%)
  }

  .icon-6 {
    grid-area: 2 / 3;
    z-index: 1;
    transform: translateY(73%) translateX(-13%);
  }

  .icon-7 {
    grid-area: 1 / 2;
    z-index: 1;
    transform: translateY(-8%) translateX(-1%);

  }
  .containerImageBlock-0 {
    transform: translateY(33.5%) translateX(-105%);
    top: 0;
    left: 0;
  }
  .containerImageBlock-1 {
    transform:translateY(33%) translateX(29.5%);
    top: 0;
  }
  .containerImageBlock-2 {
    transform: translateY(66.5%) translateX(-27%);
    left: 0;
  }
  .containerImageBlock-4 {
    transform: translateY(-127%) translateX(-50%);
    top: 0;
  }
  .containerImageBlock-5 {
    transform:translateY(-72%) translateX(31%);
    top: 0;
  }
  .containerImageBlock-6 {
    transform: translateY(-75%) translateX(-102%);
    left: 0;
    top: 0;
  }

  .activeTitle {
    line-height: 18px;
    font-size: 22px;
  }
  .activeText {
    font-size: 18px;
  }

  .subTitleVerticalSoty {
    padding: 11px 14px;
    width: 350px;
  }

  .subTitleVerticalSoty-0 {
    right: 11%;
    bottom: -129%;
  }
  .subTitleVerticalSoty-1 {
    left: 101%;
    bottom: 43%;
  }

  .subTitleVerticalSoty-2 {
    right: 96%;
    bottom: 40%;
  }

  .subTitleVerticalSoty-6 {
    right: 97%;
    bottom: -93%;
  }

  .subTitleVerticalSoty-4 {
    left: 100%;
    bottom: -97%;
  }

  .subTitleVerticalSoty-5 {
    left: 15%;
    bottom: -156%;
  }
}

@media (max-width: 1200px) {
  .subTitleVerticalSoty {
    background: rgba(0, 0, 0, 1);
    font-size: 18px;
    border-radius: 20px ;
    width: 300px;
    padding: 18px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: white;
    backdrop-filter: blur(15px);
    position: absolute;
    bottom: -120px;
  }
  .main {
    margin: 40px 0 100px 0;
  }
  .imageProfiles {
    width: 160px;
    height: 135px;
  }
  .containerIcons {
     border-radius: 50%;
    width: 520px ;
    height: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid  rgba(255, 255, 255, 0.1);
  }
  .containerIcons::before,
  .containerIcons::before {
    max-width: 500px;
    height: 500px;
    width: 100%;
  }
  .containerIcons::after {
    max-width: 480px;
    height: 480px;
    width: 100%;
  }
 .containerImageBlock-0 {
  transform: translateY(41%) translateX(-97%);
  top: 0;
  left: 0;
}
  .containerImageBlock-1 {
    transform:translateY(37%) translateX(31%);
    top: 0;
  }
  .containerImageBlock-2 {
    transform: translateY(67.5%) translateX(-23%);
    left: 0;
  }
  .containerImageBlock-4 {
    transform: translateY(-117%) translateX(-47%);
    top: 0;
  }
  .containerImageBlock-5 {
    transform:translateY(-65%) translateX(29%);
    top: 0;
  }
  .containerImageBlock-6 {
    transform: translateY(-64%) translateX(-97%);
    left: 0;
    top: 0;
  }
  .icon-1 {
    grid-area: 1 / 1;
    transform: translateY(71.5%) translateX(17%);
    z-index: 1;
  }
  .icon-2 {
    grid-area: 3 / 2;
    transform: translateY(2%) translateX(0%);
    z-index: 1;
  }
  .icon-3 {
    grid-area: 3 / 1;
    transform: translateY(-69.5%) translateX(19%);
    z-index: 1;
  }
  .icon-4 {
    grid-area: 2 / 2;
    z-index: -1;
    box-shadow: 0px 0px 105px 45px white;
  }
  .icon-5 {
    grid-area: 1 / 3;
    z-index: 1;
    transform:translateY(70%) translateX(-19%);
  }
  .icon-6 {
    grid-area: 2 / 3;
    z-index: 1;
    transform: translateY(70%) translateX(-18%)
  }
  .icon-7 {
    grid-area: 1 / 2;
    z-index: 1;
    transform: translateY(-1%) translateX(-2%);
  }

  .imageProfiles {
    width: 160px;
    height: 130px;
  }
  .imageProfiles-0 {
    transform:translateY(2%) translateX(0%);
  }
  .imageProfiles-1 {
    transform:translateY(2%) translateX(1.5%);
  }
  .imageProfiles-2 {
    transform:translateY(4%) translateX(-0.5%);
  }
  .imageProfiles-3 {
    transform:translateY(-7.5%) translateX(-2.5%);
  }
  .imageProfiles-4 {
    transform:translateY(-0.5%) translateX(-0.5%);
  }
  .imageProfiles-5 {
    transform:translateY(0%) translateX(0%);
  }
  .imageProfiles-6 {
    transform:translateY(0%) translateX(-1%);
  }

  .activeTitle {
    font-size: 21px;
    line-height: 17px;
  }
  .activeText {
    font-size: 16px;
  }

  .subTitleVerticalSoty-0 {
    right: 15%;
    bottom: -134%;
  }
  .subTitleVerticalSoty-1 {
    left: 100%;
    bottom: 30%;
  }

  .subTitleVerticalSoty-2 {
    right: 90%;
    bottom: 75%;
  }
  .subTitleVerticalSoty-4 {
    left: 97%;
    bottom: -93%;
  }

  .subTitleVerticalSoty-5 {
    left: 16%;
    bottom: -149%;
  }
  .subTitleVerticalSoty-6 {
    right: 96%;
    bottom: -85%;
  }
}

@media (max-width: 1024px) {
  .containerAudienceProfiles {
    margin-top: 100px;
  }
  .titleAudience {
    font-size: 40px;
  }
  .activeText {
    font-size: 19px;
  }
  .subTitleVerticalSoty {
    width: 700px;
    padding: 20px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%) translateY(145%);

   }
  .subTitleVerticalSoty-0 {
    transform:translateX(-49%) translateY(141%);
   }
  .subTitleVerticalSoty-1 {
    transform:translateX(-49%) translateY(284%);;
    bottom: 0;
  }

  .subTitleVerticalSoty-2 {
    transform:translateX(-49%) translateY(284%);;
    bottom: 0;
   }
  .subTitleVerticalSoty-4 {
    transform: translateX(-50.5%) translateY(186%);
   }

  .subTitleVerticalSoty-5 {
    transform:translateX(-49%) translateY(284%);;
    bottom: 0;
  }
  .subTitleVerticalSoty-6 {
    transform: translateX(-50%) translateY(195%);
   }
  .main {
    align-items: center;
    margin: 50px 0 50px 0;
  }
  .imageProfiles {
    width: 145px;
    height: 123px;
    margin: 0;
   }
  .containerIcons {
    border-radius: 50%;
    width: 500px ;
    height: 500px;
  }
  .containerIcons::before {
    max-width: 480px;
    height: 480px;
    width: 100%;
  }
  .containerIcons::after {
    max-width: 460px;
    height: 460px;
    width: 100%;
  }

  .imageProfiles-0 {
    transform:translateY(1%) translateX(4%);
  }

  .imageProfiles-1 {
    transform:translateY(0%) translateX(0.5%);
  }
  .imageProfiles-2 {
    transform:translateY(-1%) translateX(3.5%);
  }
  .imageProfiles-3 {
    transform:translateY(-7.5%) translateX(-2.5%);
  }
  .imageProfiles-4 {
    transform:translateY(1.5%) translateX(-2.5%);
  }

  .imageProfiles-5 {
    transform:translateY(.5%) translateX(-3%);
  }

  .imageProfiles-6 {
    transform:translateY(2%) translateX(0%);
   }

  .containerImageBlock-0 {
    transform: translateY(43%) translateX(60%);

  }
  .containerImageBlock-1 {
    transform:translateY(-116%) translateX(-50%);

  }
  .containerImageBlock-2 {
    transform: translateY(-86%) translateX(60%);
   }
  .containerImageBlock-4 {
    transform:translateY(42.5%) translateX(-127%);
   }
  .containerImageBlock-5 {
    transform:translateY(-64%) translateX(-126%);
   }
  .containerImageBlock-6 {
    transform: translateY(95%) translateX(-15.5%);
  }
  .icon-1 {
     transform: translateY(74.5%) translateX(24%);
   }
  .icon-2 {
     transform: translateY(-8%) translateX(-2%);
   }
  .icon-3 {
     transform: translateY(-74.5%) translateX(25%);
   }
  .icon-4 {
    box-shadow: none;
  }
  .icon-5 {
    transform:translateY(75%) translateX(-25%);
  }
  .icon-6 {
    transform: translateY(66.5%) translateX(-26%);
  }
  .icon-7 {
    transform: translateY(9%) translateX(-0.5%);
  }
}

@media (max-width: 720px) {
  .main {
    padding-bottom: 100px;
  }
  .titleAudience {
    font-size: 35px;
    padding: 20px 0 20px 0;
  }
  .wrapperIconsBlock {
    padding: 0;
  }
  .subTitleVerticalSoty {
    width: 500px;
    padding: 20px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%) translateY(145%);

  }
  .subTitleVerticalSoty-0 {
    transform:translateX(-49%) translateY(141%);
  }
  .subTitleVerticalSoty-1 {
    transform:translateX(-50%) translateY(262%);
    bottom: 0;
  }

  .subTitleVerticalSoty-2 {
    transform:translateX(-49%) translateY(261%);
    bottom: 0;
  }
  .subTitleVerticalSoty-4 {
    transform: translateX(-50.5%) translateY(170%);
  }

  .subTitleVerticalSoty-5 {
    transform:translateX(-51%) translateY(263%);
    bottom: 0;
  }
  .subTitleVerticalSoty-6 {
    transform: translateX(-50%) translateY(186%);
  }
}

@media (max-width: 500px)  {
  .activeTitle {
    font-size: 20px;
  }
  .titleAudience {
    font-size: 38px;
    width: 350px;
    margin: auto;
   }
  .subTitleVerticalSoty {
    padding: 15px;
    font-size: 15px;
    width: 400px;
    margin: 0;
  }

  .imageProfiles-0 {
    transform:translateY(1%) translateX(-1%);
  }

  .imageProfiles-1 {
    transform:translateY(-1%) translateX(0%);
  }
  .imageProfiles-2 {
    transform:translateY(1%) translateX(-0.5%);
  }
  .imageProfiles-3 {
    transform:translateY(-7.5%) translateX(-2.5%);
  }
  .imageProfiles-4 {
    transform:translateY(-0.5%) translateX(0.5%);
  }

  .imageProfiles-5 {
    transform:translateY(-0.5%) translateX(-1.5%);
  }

  .imageProfiles-6 {
    transform:translateY(0.5%) translateX(-1%);
  }

  .subTitleVerticalSoty {
    width: 398px;
    padding: 20px;
    text-align: center;
    margin: 0;
    left: 50%;
    transform: translateX(-50%) translateY(145%);

  }
  .subTitleVerticalSoty-0 {
    transform:translateX(-50%) translateY(121%);
  }
  .subTitleVerticalSoty-1 {
    transform:translateX(-50%) translateY(236%);
    bottom: 0;
  }

  .subTitleVerticalSoty-2 {
    transform:translateX(-50%) translateY(236%);
    bottom: 0;
  }
  .subTitleVerticalSoty-4 {
    transform: translateX(-50%) translateY(144%);
  }

  .subTitleVerticalSoty-5 {
    transform:translateX(-50%) translateY(205%);
    bottom: 0;
  }
  .subTitleVerticalSoty-6 {
    transform: translateX(-50%) translateY(156%);
  }
  .imageFlex {
    display: flex;
    justify-content: center;
  }
  .imageProfiles {
    width: 120px;
    height: 105px;
    margin: 0;
  }
  .containerIcons {
    border-radius: 50%;
    width: 390px ;
    height: 390px;

  }
  .containerIcons::before {
    max-width: 380px;
    height: 380px;
    width: 100%;
  }
  .containerIcons::after {
    max-width: 360px;
    height: 360px;
    width: 100%;
  }
  .containerImageBlock-0 {
    transform: translateY(49%) translateX(72%);

  }
  .containerImageBlock-1 {
    transform:translateY(-105.5%) translateX(-50%);

  }
  .containerImageBlock-2 {
    transform: translateY(-84%) translateX(71%);
  }
  .containerImageBlock-4 {
    transform:translateY(51%) translateX(-131%);;
  }
  .containerImageBlock-5 {
    transform:translateY(-54%) translateX(-128.1%);
  }
  .containerImageBlock-6 {
    transform: translateY(99%) translateX(-7%);

  }
  .icon-1 {
    transform: translateY(85%) translateX(38%);
  }
  .icon-2 {
    transform: translateY(-32%) translateX(-2%);
  }

  .icon-3 {
    transform: translateY(-85.5%) translateX(39%);
  }

  .icon-4 {
    box-shadow: none;
  }

  .icon-5 {
    transform: translateY(85%) translateX(-40%);
  }

  .icon-6 {

    transform:translateY(54.5%) translateX(-42%);
  }

  .icon-7 {
    transform: translateY(33%) translateX(-1.6%);
  }
}
@media (max-width: 400px)  {
  .titleAudience {
    font-size: 30px;
    width: 320px;
    margin: auto;
  }
  .activeTitle {
    font-size: 19px;
    margin-bottom: 5px;
  }
  .activeText {
    font-size: 14px;
  }
  .subTitleVerticalSoty {
    padding: 12px;
    font-size: 15px;
    width: 300px;
  }
  .subTitleVerticalSoty {
    width: 338px;
    padding: 20px;
    text-align: center;
    margin: 0;
    left: 50%;
    transform: translateX(-50%) translateY(145%);

  }
  .subTitleVerticalSoty-0 {
    transform:translateX(-50%) translateY(129%);
  }
  .subTitleVerticalSoty-1 {
    transform:translateX(-50%) translateY(226%);
    bottom: 0;
  }

  .subTitleVerticalSoty-2 {
    transform:translateX(-50%) translateY(226%);
    bottom: 0;
  }
  .subTitleVerticalSoty-4 {
    transform: translateX(-50%) translateY(146%);
  }

  .subTitleVerticalSoty-5 {
    transform:translateX(-50%) translateY(197%);
    bottom: 0;
  }
  .subTitleVerticalSoty-6 {
    transform: translateX(-50%) translateY(156%);
  }

  .wrapperIconsBlock {
    padding: 0px 0 80px 0;
  }

  .imageProfiles {
    width: 96px;
    height: 85px;
    margin: 0;
  }

  .containerIcons {
    border-radius: 50%;
    width: 340px ;
    height: 340px;

  }
  .containerIcons::before {
    max-width: 330px;
    height: 330px;
  }
  .containerIcons::after {
    max-width: 320px;
    height: 320px;
  }


  .containerImageBlock-0 {
    transform: translateY(58.5%) translateX(82.5%);;

  }
  .containerImageBlock-1 {
    transform:translateY(-92%) translateX(-51%);

  }
  .containerImageBlock-2 {
    transform: translateY(-78%) translateX(79.5%);
  }
  .containerImageBlock-4 {
    transform:translateY(59%) translateX(-129%)
  }
  .containerImageBlock-5 {
    transform:translateY(-44%) translateX(-127.6%);
  }
  .containerImageBlock-6 {
    transform: translateY(108%) translateX(4%);

  }
  .icon-1 {
    transform:translateY(97.5%) translateX(43%);
  }
  .icon-2 {
    transform: translateY(-54%) translateX(-1%);
  }

  .icon-3 {
    transform: translateY(-96.5%) translateX(45%);
  }

  .icon-4 {
    box-shadow: none;
  }

  .icon-5 {
    transform:translateY(97%) translateX(-45%);
  }

  .icon-6 {

    transform: translateY(44.5%) translateX(-46%);
  }

  .icon-7 {
    transform: translateY(55%) translateX(-1.5%);
  ;
  }
}