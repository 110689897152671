.wrapperProfile {
  display: flex;
  min-height: calc(100vh - 110px);
  border-top: 1px solid rgba(255, 255, 255, 0.10);
 }

.titleProfile {
  color: #FFF;
  font-family: Goldman;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%; /* 36px */
  letter-spacing: -1.6px;
  text-transform: uppercase;
  margin: 40px 0 0 40px;
}

.pagesProfile {
  background: #0E0E0E;;
  flex: 1;
  position: relative;
  overflow-y: hidden;
 }

.mainSideBar {
  border-right: 1px solid rgba(255, 255, 255, 0.10);
}


