
@media (max-width: 1920px) {
  .starRomb {
    width: 30px;
  }
}

@media (max-width: 1536px) {
  .starRomb {
    width: 50px;
  }
}