.logoImage {
  width: 600px;
  user-select: none;
}

.containerShowCase {
  z-index: 2;
  min-height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.btnWrapperHeader {
  width: 288px;
  margin: auto;
  text-align: center;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Масштабируем видео, чтобы оно заполняло весь блок */
  z-index: -1; /* Задний план */
  pointer-events: none; /* Отключаем взаимодействие с видео */
  backdrop-filter: blur(10px);
}

.logoBtnCase {
  position: relative;
}
.buttonContainer {
  width: 100%;
  margin: 40px auto 0 auto;
}
.buttonContainer {
  position: relative;
  display: inline-block;
  background-color: #ff4500; /* Оранжевый фон */
  transition: .3s;
  border-radius: 13px;
}

.button {
  color: white;
  border: none;
  padding: 20px 0;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  z-index: 1;
   cursor: pointer;
}

.buttonContainer:hover {
  background-color: #FF2E00;
  transition: .3s;
}
.dot {
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  z-index: 2; /* Поверх кнопки */
}


@media screen and (max-width: 1024px) {
  .logoImage {
    width: 438px;
  }
}

@media screen and (max-width: 500px) {
  .logoImage {
    width: 328px;
  }
}

@media screen and (max-width: 328px) {
  .logoImage {
    width: 256px;
  }
}